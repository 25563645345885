import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LookupContract } from '../cross-cutting/models';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { TsGroupByPipe } from '../cross-cutting/pipes';
import PerfectScrollbar from 'perfect-scrollbar';
import { randomId } from '../cross-cutting/util';

export type TsSelectOptionMetadata = {
  disabled?: boolean;
};

@Component({
  selector: 'ts-select',
  templateUrl: './ts-select.component.html',
  styleUrls: ['./ts-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TsSelectComponent,
    },
  ],
})
export class TsSelectComponent extends OnDestroyMixin implements OnInit, ControlValueAccessor {
  @Input() items: (any & TsSelectOptionMetadata)[];

  @Input() set disabled(value: boolean) {
    this.isDisabled = value;
  }

  @Input() tooltip: string;
  @Input() lookupContract: LookupContract;
  @Input() itemKey;
  @Input() itemLabel;
  @Input() hasGroups = false;
  @Input() uiOptions = {
    roundedBorders: true,
    placeholder: 'select',
    searchPlaceholder: 'Search...',
    size: 'medium',
  };

  @ViewChild('searchInput') searchInput: ElementRef;

  selectedItem: any;
  searchFormControl = new FormControl('');
  searchValue$: Observable<string>;
  isPanelOpen = new BehaviorSubject(false);
  isDisabled = false;
  ui = {
    splashId: '',
    optionsPanelId: '',
  };

  private perfectScrollbar: PerfectScrollbar;

  constructor() {
    super();

    this.ui = {
      splashId: `x${randomId()}`,
      optionsPanelId: `x${randomId()}`,
    };

    this.filterFn.bind(this);
  }

  ngOnInit(): void {
    this.searchValue$ = this.searchFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.perfectScrollbar.update()),
    );
  }

  writeValue(obj: any): void {
    this.selectedItem = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onOptionSelect(event: Event, option: any): void {
    if (option.disabled) return;

    if (option.isGroup) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (option[this.itemKey] !== this.selectedItem) {
      this.selectedItem = option[this.itemKey];
      this.onChange(option[this.itemKey]);
    }
    this.closeOptionsPanel();
  }

  getSelectedItemInfo(): { label: string; groupLabel?: string } {
    const field = this.items?.find((x) => x[this.itemKey] === this.selectedItem);

    return {
      label: field?.[this.itemLabel],
      groupLabel: field?.meta?.group?.label,
    };
  }

  @HostListener('document:click', ['$event'])
  documentClickHandler(event: any): void {
    if (this.isDisabled) {
      return;
    }

    if (document.querySelector(`.${this.ui.splashId}`)?.contains(event.target)) {
      this.openOptionsPanel();
      return;
    }

    const opened = document.querySelector(`.${this.ui.optionsPanelId}`);

    if (opened && !opened.contains(event.target)) {
      this.closeOptionsPanel();
    }
  }

  openOptionsPanel(): void {
    this.isPanelOpen.next(true);
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.perfectScrollbar =
        this.perfectScrollbar ??
        new PerfectScrollbar('.container__options-panel__list', {
          wheelSpeed: 1,
          minScrollbarLength: 20,
        });
    }, 0);
  }

  closeOptionsPanel(): void {
    this.isPanelOpen.next(false);
  }

  filterFn = (values: any, args: any): any[] => {
    if (!args) {
      return values;
    }

    if (!this.hasGroups) {
      return values.filter((x) => !x.isGroup && x[this.itemLabel]?.toLowerCase().includes(args.toLowerCase()));
    }

    return new TsGroupByPipe().transform(
      values.filter((x) => !x.isGroup && x[this.itemLabel].toLowerCase().includes(args.toLowerCase())),
      true,
    );
  };

  private onChange = (value: any) => {};
}
