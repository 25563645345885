<mat-form-field
  class="ts-input"
  [ngClass]="{ 'squared-border': !roundedBorders, slim: variant === 'slim', large: size === 'lg', sm: size === 'sm' }"
  appearance="fill"
>
  <ts-icon *ngIf="icon" [icon]="icon" color="black"></ts-icon>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    [readonly]="readonly"
    [attr.data-cy]="dataCy"
    [autofocus]="autofocus"
    [type]="inputType"
    [tabIndex]="tabindex"
    [placeholder]="placeholder"
    [formControl]="control"
    [autocomplete]="autocomplete"
    [min]="min"
    [max]="max"
    [step]="step"
    [matAutocomplete]="matAutocompleteComponentRef"
    [matAutocompleteDisabled]="!autoCompleteComponentRef"
    (blur)="blur.emit()"
  />
  <ng-content select="[ts-autocomplete]"></ng-content>
  <mat-error *ngIf="control?.errors && errorMessages">
    <ng-container *ngFor="let error of control?.errors | keyvalue">
      <p class="mb-2" *ngIf="errorMessages[error.key]?.length > 0">{{ errorMessages[error.key] }}</p>
    </ng-container>
  </mat-error>
</mat-form-field>
