/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { filter, switchMap } from 'rxjs/operators';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Store } from '@ngxs/store';
import { UserState } from '@state/user/state';
import { MentionConfig } from 'angular-mentions';
import trim from 'lodash-es/trim';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Comment } from 'app/core/models/comment.types';
import { User } from 'app/core/models/user.types';
import { CommentsService } from 'app/core/services/comments.service';
import { Project } from 'app/modules/projects/projects.types';
import { Account, Team } from 'app/core/models/account.types';
import { ConfirmService } from '../dialog/confirm/confirm.service';
import { RelatedToType } from 'app/shared/enums';
import { QUILL_MODULES } from 'app/shared/Utils/quill-editor.utils';
import { TeamsService } from 'app/modules/settings/teams/services/teams.service';
@Component({
  selector: 'comment-view',
  templateUrl: './comment-view.component.html',
  styleUrls: ['./comment-view.component.scss'],
})
export class CommentViewComponent extends OnDestroyMixin implements OnInit, OnChanges {
  @Input() account: Account;
  @Input() relatedToId: number;
  @Input() relatedToType: RelatedToType;
  @Input() readonly: boolean = false;
  @Output() commentChanges = new EventEmitter<boolean>();

  private _mentionMappings: { [userFriendlyMention: string]: string } = {};

  public comments: Comment[];
  public isPosting = false;
  public commentForm: UntypedFormGroup;
  public replyCommentForm: UntypedFormGroup;
  public editCommentForm: UntypedFormGroup;
  public user: User = this.store.selectSnapshot(UserState.getUser);
  public users: User[] = this.store.selectSnapshot(UserState.getUsers);
  public teams: Team[];
  public quillModules = QUILL_MODULES;
  public mentionConfig: MentionConfig = {
    mentions: [
      {
        items: [],
        triggerChar: '@',
        labelKey: 'full_name',
        allowSpace: true,
        maxItems: 4,
        mentionSelect: this.userMentioned.bind(this),
      },
      {
        items: [],
        triggerChar: '#',
        labelKey: 'full_name',
        allowSpace: true,
        maxItems: 4,
        mentionSelect: this.userMentioned.bind(this),
      },
    ],
  };

  constructor(
    private readonly _commentsService: CommentsService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _projectsService: ProjectsService,
    private readonly _confirmService: ConfirmService,
    private readonly _teamsService: TeamsService,
    private readonly store: Store,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changedRelatedToId = changes.relatedToId && !changes.relatedToId.isFirstChange();
    const changedRelatedToType = changes.relatedToType && !changes.relatedToType.isFirstChange();

    if (changedRelatedToId || changedRelatedToType) {
      this.loadComments();
    }
  }

  ngOnInit(): void {
    this._commentsService.accountId = this.account.id;
    this.buildForms();
    this.populateMentionUsers();
    this.populateMentionTeams();
    this.populateMentionContactAssociations();
    this.loadComments();
  }

  private buildForms(): void {
    this.commentForm = this._formBuilder.group({
      body: ['', [Validators.required]],
    });

    this.editCommentForm = this._formBuilder.group({
      id: [],
      body: ['', [Validators.required]],
    });

    this.replyCommentForm = this._formBuilder.group({
      id: [],
      parent_id: [],
      body: ['', [Validators.required]],
    });
  }

  private populateMentionUsers(): void {
    this.mentionConfig.mentions[0].items = [
      ...this.users.filter((user) => user.full_name !== null),
      { full_name: 'everyone', isContact: false },
    ];
  }

  private populateMentionTeams(): void {
    this._teamsService
      .getAllTeams()
      .pipe(untilComponentDestroyed(this))
      .subscribe((teams: Team[]) => {
        this.teams = teams;
        this.mentionConfig.mentions[0].items.push(
          ...this.teams.map((team) => ({
            full_name: team.mention_name,
            isContact: false,
          })),
        );
      });
  }

  private populateMentionContactAssociations(): void {
    this._projectsService.project$.pipe(untilComponentDestroyed(this)).subscribe((project: Project) => {
      if (project) {
        this.mentionConfig.mentions[1].items = project?.contact_associations?.map((user) => {
          if (user?.contact) {
            const { first_name, last_name } = user.contact;
            user.contact.full_name = trim(`${first_name ? first_name : ''} ${last_name ? last_name : ''}`) || 'unknown';
            user.contact.isContact = true;
            return user?.contact;
          }
          return { first_name: '', last_name: '' };
        });
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  private loadComments(): void {
    this._commentsService
      .getComments(this.relatedToId, this.relatedToType)
      .pipe(untilComponentDestroyed(this))
      .subscribe((comments) => {
        this.comments = comments;
        this._changeDetectorRef.markForCheck();
      });
  }

  private userMentioned(value: User): string {
    const userFriendlyMention = `@${value.full_name.replace(/\s/g, '')}`;

    // Logic to determine if backend mention should include concatenated ID
    if (
      value.full_name === 'everyone' ||
      this.teams.map((tm) => tm.mention_name.toLowerCase()).includes(value.full_name?.toLowerCase())
    ) {
      // Special cases: "everyone" and team mentions
      this._mentionMappings[userFriendlyMention] = userFriendlyMention; // No concatenated ID
      return userFriendlyMention;
    }

    // Default case: concatenate ID for backend
    const backendFriendlyMention = `@${userFriendlyMention}_${value.id}`;
    this._mentionMappings[userFriendlyMention] = backendFriendlyMention; // Store in mapping
    return userFriendlyMention;
  }

  private transformMentionsForBackend(body: string): string {
    // Replace user-friendly mentions with backend mentions
    let transformedBody = body.replace(/@(\w+)/g, (match: string) => {
      const backendFormat = this._mentionMappings[match];
      return backendFormat || match;
    });

    // Remove possible consecutive '@' characters, leaving only a single '@'
    transformedBody = transformedBody.replace(/@{2,}/g, '@');

    return transformedBody;
  }

  /**
   * Used to sanitize the comment body by removing concatenated IDs from mentions so we have a friendly mention display on edit.
   * @param body comment body property.
   * @returns any mention present in the comment body without concatenated IDs.
   */
  private sanitizeCommentBody(body: string): string {
    return body.replace(/[_]\d+\b/g, '');
  }

  saveComment(formDirective: FormGroupDirective): void {
    const comment = { ...this.commentForm.value };

    comment.body = this.transformMentionsForBackend(comment.body);
    comment.commentable_type = this.relatedToType;
    comment.commentable_id = this.relatedToId;

    this.isPosting = true;

    this._commentsService
      .createComment(comment)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.isPosting = false;
        formDirective.resetForm();
        this.commentForm.reset();
        this.loadComments();
        this.commentChanges.emit(true);
      });
  }

  editComment(comment: Comment): void {
    const sanitizedBody = this.sanitizeCommentBody(comment.body);
    this.editCommentForm.patchValue({
      id: comment.id,
      body: sanitizedBody,
    });
    comment.editing = true;
  }

  replyToComment(comment: Comment): void {
    comment.replying = true;
    this.replyCommentForm.get('parent_id').setValue(comment.id);
  }

  cancelCommentReply(comment: Comment): void {
    comment.replying = false;
    this.replyCommentForm.get('parent_id').setValue(null);
  }

  saveCommentReply(): void {
    const comment = { ...this.replyCommentForm.value };

    comment.body = this.transformMentionsForBackend(comment.body);
    comment.commentable_type = this.relatedToType;
    comment.commentable_id = this.relatedToId;

    this._commentsService
      .createComment(comment)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.replyCommentForm.reset();
        this.loadComments();
        this.commentChanges.emit(true);
      });
  }

  deleteComment(comment: Comment): void {
    this._confirmService
      .openConfirmationModal()
      .pipe(
        untilComponentDestroyed(this),
        filter((result: boolean) => result),
        switchMap(() => this._commentsService.deleteComment(comment.id)),
      )
      .subscribe(() => {
        this.loadComments();
        this.commentChanges.emit(true);
      });
  }

  cancelEditComment(comment: Comment): void {
    comment.editing = false;
  }

  updateComment(formDirective: FormGroupDirective): void {
    const comment = { ...this.editCommentForm.value };

    comment.body = this.transformMentionsForBackend(comment.body);

    this._commentsService
      .updateComment(comment)
      .pipe(untilComponentDestroyed(this))
      .subscribe((comment) => {
        formDirective.resetForm();
        this.editCommentForm.reset();
        comment.editing = false;
        this.loadComments();
        this.commentChanges.emit(true);
      });
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  formatMessage(body: string): string {
    if (!body) {
      return '';
    }

    return body
      .replace(/\B@\w+/g, (match: string) => `<mark class="mentioned-contact">${match.split('_').shift()}</mark>`)
      .replace(
        /(?<!&)\B#\w+/g,
        (match: string) =>
          `<a href="/contacts/${match.split('_')?.pop()}" class="link" target="_blank">${match.split('_').shift()}</a>`,
      );
  }
}
