import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  TaskExportDialogComponent,
  TaskExportDialogResult,
} from '@shared/modules/legacy-tasks/task-export-dialog/task-export-dialog.component';
import { TsDialogComponent } from 'tsui/lib/ts-dialog/ts-dialog.component';
import { filter, Observable } from 'rxjs';
import { TsSnackbarService } from 'tsui';
import { generateDownloadableFile } from '@shared/functions';
import { FileFormat } from '@shared/enums';
import { inject, Injectable } from '@angular/core';

export interface TaskExportFileResponse {
  data: string | { message?: string };
}

@Injectable({ providedIn: 'root' })
export class TaskExportUtilsService {
  private readonly dialog = inject(Dialog);
  private readonly snackbar = inject(TsSnackbarService);

  static get crmDefaultSelectColumns(): string {
    return 'title,description,workflow_state,due_date';
  }

  public handleTaskExport(data: string | { message?: string }, format: 'csv' | 'pdf', fileName: string): void {
    if (typeof data === 'string') {
      this.onDownloadStart();
      TaskExportUtilsService.handleExportData(data, format, fileName);
    } else {
      this.onExportResultWithMessage(data?.message);
    }
  }

  static handleExportData(data: string, format: 'csv' | 'pdf', fileName?: string): void {
    if (data) {
      if (format == 'csv') {
        generateDownloadableFile(data, FileFormat.CSV, fileName || 'Tasks');
      } else if (format == 'pdf') {
        generateDownloadableFile(data, FileFormat.PDF, fileName || 'Tasks');
      }
    }
  }

  public onDownloadStart(): void {
    this.snackbar.open('Download will begin shortly.', 'info', null, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  public onExportResultWithMessage(message: string): void {
    this.snackbar.open(message, 'info', null, {
      duration: 3000,
    });
  }

  public openTaskExportDialog(): Observable<TaskExportDialogResult> {
    const dialogRef: DialogRef<TaskExportDialogResult> = this.dialog.open(TaskExportDialogComponent, {
      ...TsDialogComponent.getDefaultDialogConfig(),
    });

    return dialogRef.closed.pipe(filter((result) => !!result));
  }
}
