<ts-dialog title="Export" (closeDialog)="closeDialog()">
  <div ts-dialog-body>
    <span class="flex flex-row items-center justify-between">
      <p>File Type</p>
      <ts-select
        itemKey="value"
        itemLabel="label"
        [formControl]="fileTypeForm"
        class="w-1/2"
        [hasGroups]="false"
        [disabled]="false"
        [uiOptions]="{
          roundedBorders: false,
          placeholder: 'Select a task',
          searchPlaceholder: 'Task title...',
          size: 'full',
        }"
        [items]="fileTypeOptions"
      ></ts-select>
    </span>
  </div>
  <div class="ts-dialog-actions-container" ts-dialog-actions>
    <ts-button variant="secondary" (click)="closeDialog()">Cancel</ts-button>
    <ts-button variant="primary" (click)="closeDialog(fileTypeForm.getRawValue())">Download</ts-button>
  </div>
</ts-dialog>
