<div class="flex flex-col gt-xs:flex-row">
  <mat-form-field class="flex-auto gt-xs:pr-3">
    <input
      matInput
      [(ngModel)]="project.title"
      (blur)="onBlur()"
      [appAuthorize]="[NewPermission.DEALS_EDIT, project.permissions?.is_editable]"
    />
  </mat-form-field>
</div>
