import { Injectable } from '@angular/core';
import { Task, TaskStatus } from '../tasks.types';
import { ApprovalStatus } from '@shared/modules/approvals/approvals.types';
export const TASK_WORKFLOW_STATUES: TaskStatus[] = [
  {
    key: 'n_a',
    label: 'N/A',
    color: 'gray',
    icon: 'more_horiz',
  },
  {
    key: 'not_started',
    label: 'Not Started',
    color: 'gray',
    icon: 'more_horiz',
  },
  {
    key: 'needs_review',
    label: 'Needs Review',
    color: 'red',
    icon: 'error_outline',
  },
  {
    key: 'in_progress',
    label: 'In Progress',
    color: 'blue',
    icon: 'more_horiz',
  },
  {
    key: 'in_approval',
    label: 'In Approval',
    color: 'yellow-300',
    icon: 'more_horiz',
  },
  {
    key: 'complete',
    label: 'Complete',
    color: 'green',
    icon: 'done',
  },
];
@Injectable({
  providedIn: 'root',
})
export class TaskStatusService {
  constructor() {}

  getNextStatus(task: Task, approval = false, permission = false) {
    switch (task.workflow_state) {
      case 'n_a':
      case 'not_started':
        return 'in_progress';
      case 'in_progress':
      case 'needs_review':
        if (approval) {
          if (task.approvals?.length > 0) {
            return 'in_approval';
          } else {
            return permission ? 'in_approval' : 'complete';
          }
        }
        return 'complete';
      case 'in_approval':
        if (!task.approvals || task.approvals?.length <= 0) {
          return 'complete';
        }
        const approved = task.approvals?.every((a) => a.status === ApprovalStatus.APPROVED);
        if (approved) {
          return 'complete';
        } else {
          return 'in_approval';
        }
      default:
        return task.workflow_state;
    }
  }

  getNextButtonLabel(task: Task, approval = false, permission = false) {
    switch (task.workflow_state) {
      case 'n_a':
      case 'not_started':
        return 'Start Task';
      case 'in_progress':
      case 'needs_review':
        if (approval) {
          if (task.approvals?.length > 0) {
            return 'Request Approval';
          } else {
            return permission ? 'Request Approval' : 'Complete';
          }
        }
        return 'Complete';
      case 'in_approval':
        if (!task.approvals || task.approvals?.length <= 0) {
          return 'Complete';
        }

        const approved = task.approvals?.every((a) => a.status === ApprovalStatus.APPROVED);
        if (approved) {
          return 'Complete';
        } else {
          return 'Request Approval';
        }
      default:
        return 'Complete';
    }
  }

  getAvailableWorkflowStates(task: Task): TaskStatus[] {
    return TASK_WORKFLOW_STATUES.map((state: TaskStatus) => this.validateTaskState(task, state));
  }

  private validateTaskState(task: Task, workflowState): TaskStatus {
    // Disabling COMPLETE status if there are approvals and not all are approved
    if (
      workflowState.key === 'complete' &&
      task.approvals?.length > 0 &&
      task.approvals?.some((a) => a.status !== ApprovalStatus.APPROVED)
    ) {
      return {
        ...workflowState,
        disabled: true,
        disabledReason: 'All stakeholders must approve the task in order to complete the task.',
      };
    }

    return { ...workflowState, disabled: false };
  }
}
