import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ContactPagination } from '../contacts/contacts.types';
import {
  TaskList,
  Task,
  TaskListTemplate,
  TaskStatus,
  TaskResult,
  Todo,
} from '../../shared/modules/legacy-tasks/tasks.types';
import { Approval } from 'app/shared/modules/approvals/approvals.types';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { generateDownloadableFile } from '@shared/functions';
import { FileFormat } from '@shared/enums';
import {
  TaskExportFileResponse,
  TaskExportUtilsService,
} from '@shared/modules/legacy-tasks/services/task-export-utils.service';
import { TsSnackbarService } from 'tsui';

const taskListTemplatesCacheBuster$ = new Subject<void>();

export interface AggregatedTaskFilter {
  fjson: string;
  sort: { field: string; order: string }[];
  q: string;
  include_keydates: boolean;
  page: number;
  per_page: number;
  group: string;
}

@Injectable({
  providedIn: 'root',
})
export class AggregatedTasksService {
  private _taskListTemplates: BehaviorSubject<TaskList[] | null>;
  private _tasks: BehaviorSubject<Task[] | null>;
  private _pagination: BehaviorSubject<ContactPagination | null>;
  private _task: BehaviorSubject<Task | null>;
  private _accountId: string;

  constructor(
    private _httpClient: HttpClient,
    private taskExportUtilsService: TaskExportUtilsService,
  ) {
    this._taskListTemplates = new BehaviorSubject(null);
    this._tasks = new BehaviorSubject(null);
    this._task = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
  }

  get tasks$(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  get task$(): Observable<Task> {
    return this._task.asObservable();
  }

  get taskListTemplates$(): Observable<TaskListTemplate[]> {
    return this._taskListTemplates.asObservable();
  }

  get pagination$(): Observable<ContactPagination> {
    return this._pagination.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  getTaskById(taskListId: number, taskId: number): Observable<Task> {
    return this._httpClient
      .get<{ task: Task }>(`accounts/${this._accountId}/task_lists/${taskListId}/tasks/${taskId}`)
      .pipe(
        map((response) => {
          this._task.next(response.task);
          return response.task;
        }),
      );
  }

  getTasks(
    page: number = 1,
    size: number = 50,
    sort = 'due_date',
    order: string = 'DESC',
    filters: any = {},
    groupBy: string = '',
  ): Observable<{ pagination: ContactPagination; contacts: Task[] }> {
    const {
      pipelineIdIn = [],
      projectStageIdIn = [],
      workflowStateIn = [],
      assignedUserIdIn = [],
      dueDateGteq = '',
      dueDateLteq = '',
      taskTypeIn = [],
    } = filters;

    return this._httpClient
      .get<any>(`accounts/${this._accountId}/tasks`, {
        params: {
          page: '' + page,
          per_page: '' + size,
          sort: sort,
          order: order,
          group: groupBy,
          pipeline_id_in: pipelineIdIn.join(','),
          project_stage_id_in: projectStageIdIn.join(','),
          workflow_state_in: workflowStateIn.join(','),
          assigned_user_id_in: assignedUserIdIn.join(','),
          task_type_in: taskTypeIn.join(','),
          due_date_gteq: dueDateGteq,
          due_date_lteq: dueDateLteq,
        },
      })
      .pipe(
        tap((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let tasks = response.results;
          this._pagination.next(pagination);
          this._tasks.next(tasks);

          return {
            pagination,
            tasks,
          };
        }),
      );
  }

  getTasksByContactId(contactId: string): Observable<Task[]> {
    return this._httpClient.get<any>(`contacts/${contactId}/tasks?account_id=${this._accountId}`).pipe(
      map((response) => {
        this._tasks.next(response.tasks);
        return response.tasks;
      }),
    );
  }

  createTask(task: Task): Observable<Task> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.post<any>(`accounts/${this._accountId}/tasks`, { task }).pipe(
          map((response) => {
            let newTask = response.task;
            tasks = [newTask, ...tasks];
            this._tasks.next(tasks);
            return newTask;
          }),
        ),
      ),
    );
  }

  /**
   * @param task sometimes this task object can be a formValue with missing properties from original task object.
   * @param originalTaskObj original task object needed for updating a task using it's task list id value.
   * @returns updated task.
   */
  updateTask(task: Task, originalTaskObj?: Task): Observable<Task> {
    const approvalRequests = task.approvals;
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient
          .put<any>(
            `accounts/${this._accountId}/task_lists/${
              originalTaskObj ? originalTaskObj.task_list_id : task.task_list_id
            }/tasks/${task.id}?account_id=${this._accountId}`,
            { task },
          )
          .pipe(
            map((response) => {
              let updatedTask = response.task;
              updatedTask.todos = updatedTask?.todos?.sort((a: Todo, b: Todo) => a.rank - b.rank);
              updatedTask.approval_requests = approvalRequests;
              if (tasks) {
                const index = tasks.findIndex((item) => item.id === task.id);
                tasks[index] = updatedTask;
                this._tasks.next(tasks);
              }
              return updatedTask;
            }),
            switchMap((updatedTask) =>
              this.task$.pipe(
                take(1),
                filter((item) => item && item.id === updatedTask.id),
                tap(() => {
                  this._task.next(updatedTask);
                  return updatedTask;
                }),
              ),
            ),
          ),
      ),
    );
  }

  setTasks(tasks: Task[]) {
    this._tasks.next(tasks);
  }

  simpleUpdateTask(task: Task): Observable<Task> {
    return this._httpClient
      .put<any>(`accounts/${this._accountId}/tasks/${task.id}?account_id=${this._accountId}`, { task })
      .pipe(
        map((response) => {
          return response.task;
        }),
      );
  }

  deleteTask(task: Task): Observable<any> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient
          .delete<any>(
            `accounts/${this._accountId}/task_lists/${task.task_list_id}/tasks/${task.id}?account_id=${this._accountId}`,
          )
          .pipe(
            map((reponse) => {
              if (tasks) {
                const index = tasks.findIndex((item) => item.id === task.id);
                tasks.splice(index, 1);
                this._tasks.next(tasks);
              }
              return reponse.task;
            }),
          ),
      ),
    );
  }

  updateTasksOrders(tasks: Task[], draggedTask: Task): Observable<Task[]> {
    let rows = [];
    for (let task of tasks) {
      rows.push({ task_id: task.id, rank: task.rank });
    }
    return this._httpClient
      .post<any>(
        `accounts/${this._accountId}/task_lists/${draggedTask.task_list_id}/tasks/update_order?account_id=${this._accountId}`,
        { tasks: rows },
      )
      .pipe(
        take(1),
        map((response) => {
          return response.results;
        }),
      );
  }

  @Cacheable({
    cacheBusterObserver: taskListTemplatesCacheBuster$,
  })
  getTaskListTemplates(accountId?: string): Observable<TaskListTemplate[]> {
    return this._httpClient.get<any>(`task_list_templates?account_id=${this._accountId || accountId}`).pipe(
      map((response: any) => {
        this._taskListTemplates.next(response.task_list_templates);
        return response.task_list_templates;
      }),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: taskListTemplatesCacheBuster$,
  })
  createTaskListTemplate(taskListTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient.post<TaskListTemplate>(`task_list_templates?account_id=${this._accountId}`, {
      task_list_template: taskListTemplate,
    });
  }

  @CacheBuster({
    cacheBusterNotifier: taskListTemplatesCacheBuster$,
  })
  updateTaskListTemplate(taskListTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient.put<TaskListTemplate>(
      `task_list_templates/${taskListTemplate.id}?account_id=${this._accountId}`,
      { task_list_template: taskListTemplate },
    );
  }

  search(filter: AggregatedTaskFilter): Observable<TaskResult> {
    return this._httpClient.post(`accounts/${this._accountId}/tasks/search`, filter).pipe(
      map((response: TaskResult) => {
        const lastPage = Math.max(Math.ceil((response?.count ?? 0) / filter.per_page), 1);
        const begin = filter.page * filter.per_page;
        const end = Math.min(filter.per_page * (filter.page + 1), response?.count ?? 0);

        const pagination = {
          length: response?.count ?? 0,
          size: filter.per_page,
          page: filter.page - 1,
          lastPage: lastPage - 1,
          startIndex: begin,
          endIndex: end,
        };

        this._pagination.next(pagination);
        this._tasks.next(response?.results);
        return {
          count: response?.count ?? 0,
          next: response?.next ?? '',
          previous: response?.previous ?? '',
          offset: response?.offset ?? 0,
          results: response?.results,
        } as TaskResult;
      }),
    );
  }

  getTaskStatuses(taskApprovalFlow = false): Observable<TaskStatus[]> {
    let workflowStatues: TaskStatus[] = [
      {
        key: 'n_a',
        label: 'N/A',
        color: 'gray',
        icon: 'more_horiz',
      },
      {
        key: 'not_started',
        label: 'Not Started',
        color: 'gray',
        icon: 'more_horiz',
      },
      {
        key: 'needs_review',
        label: 'Needs Review',
        color: 'red',
        icon: 'error_outline',
      },
      {
        key: 'in_progress',
        label: 'In Progress',
        color: 'blue',
        icon: 'more_horiz',
      },
      {
        key: 'in_approval',
        label: 'In Approval',
        color: 'yellow-300',
        icon: 'more_horiz',
      },
      {
        key: 'complete',
        label: 'Complete',
        color: 'green',
        icon: 'done',
      },
    ];

    if (!taskApprovalFlow) {
      workflowStatues = workflowStatues.filter((status) => status.key !== 'in_approval');
    }

    return of(workflowStatues);
  }

  addApproverToTask(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `accounts/${this._accountId}/task_lists/${taskListId}/tasks/${taskId}/approvals?account_id=${this._accountId}`;

    const payload = {
      approval: {
        assigned_user_id: approval.assigned_user.id,
        task_id: taskId,
      },
    };

    return this._httpClient.post<any>(url, payload).pipe(
      take(1),
      map((response) => {
        return response.approval;
      }),
    );
  }

  removeApproverFromTask(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `accounts/${this._accountId}/task_lists/${taskListId}/tasks/${taskId}/approvals/${approval.id}?account_id=${this._accountId}`;

    return this._httpClient.delete<any>(url).pipe(
      take(1),
      map((response) => {
        return response.approval;
      }),
    );
  }

  downloadAggregatedTasks(
    format: 'csv' | 'pdf',
    select: string[],
    filter: AggregatedTaskFilter,
    savedViewTitle: string,
  ): Observable<TaskExportFileResponse> {
    const params = new HttpParams().set('format', format).set('select', select.join(','));

    return this._httpClient
      .post<TaskExportFileResponse>(`accounts/${this._accountId}/tasks/search`, filter, { params })
      .pipe(
        tap(({ data }) => {
          this.taskExportUtilsService.handleTaskExport(data, format, savedViewTitle + '-aggregated tasks');
        }),
      );
  }
}
