import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import formatMoney from 'accounting-js/lib/formatMoney.js';

@Component({
  selector: 'app-table-aggregation-renderer',
  standalone: true,
  imports: [MatMenuModule, MatIconModule, MatButtonModule],
  template: `
    <div class="flex flex-auto justify-center items-center gap-4 aggregation-hover">
      @if (formattedValue) {
        <span mt-auto mb-auto class="font-normal text-black-500">
          {{ formattedValue }}
        </span>
      }
      <div class="flex items-center gap-4 font-normal text-black-400 pointer" [matMenuTriggerFor]="actionsMenu">
        @if (aggregationType === 'total') {
          <span>Sum</span>
        } @else if (aggregationType === 'average') {
          <span>Average</span>
        } @else if (aggregationType === 'weighted_average') {
          <span>Weighted Average</span>
        } @else {
          <span>-</span>
        }
        <mat-icon class="icon-size-12 pointer">expand_more</mat-icon>
      </div>
      <mat-menu #actionsMenu="matMenu">
        <button mat-menu-item (click)="toggleAggregationType(null)">-</button>
        @if (colType !== 'percent') {
          <button mat-menu-item (click)="toggleAggregationType('total')">Sum</button>
        }
        <button mat-menu-item (click)="toggleAggregationType('average')">Average</button>
        @if (colType === 'percent') {
          <button mat-menu-item (click)="toggleAggregationType('weighted_average')">Weighted Average</button>
        }
      </mat-menu>
    </div>
  `,
  styles: [
    `
      .pointer {
        cursor: pointer;
      }
    `,
  ],
})
export class TableAggregationRendererComponent implements ICellRendererAngularComp {
  params: any;
  aggregationType;
  formattedValue: string;
  colType;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.colType = params.colDef.cellDataType;

    const fieldName = this.params.colDef.field;
    const savedAggregationType = localStorage.getItem('Project_' + fieldName) as
      | 'total'
      | 'average'
      | 'weighted_average';
    this.aggregationType = savedAggregationType;
    this.updateFormattedValue();
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.updateFormattedValue();
    return true;
  }

  toggleAggregationType(displayType: 'total' | 'average' | 'weighted_average' | null) {
    this.aggregationType = displayType;

    const fieldName = this.params?.colDef?.field;
    localStorage.setItem('Project_' + fieldName, displayType);

    this.updateFormattedValue();
  }

  updateFormattedValue() {
    const summaryColumns = this.params?.data?.summaryColumns || {};
    const fieldName = this.params?.colDef?.field;

    const transformedSummaryColumns = Object.keys(summaryColumns).reduce((acc, key) => {
      const newKey = key.replace(/^group_/, '');
      acc[newKey] = summaryColumns[key];
      return acc;
    }, {});

    const value = transformedSummaryColumns[fieldName]?.[this.aggregationType];

    this.formattedValue = value !== null && value !== undefined ? this.formatValue(value) : '';
  }

  formatValue(value: any): string {
    if (value == null) return '';

    switch (this.colType) {
      case 'money':
        return formatMoney(value);
      case 'percent':
        return `${value}%`;
      case 'number':
      case 'decimal':
        return formatNumber(value, { precision: this.params?.colDef?.meta?.scale || 0 });
      default:
        return value.toString();
    }
  }
}
