import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap } from 'rxjs/operators';

import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Store } from '@ngxs/store';
import { UserState } from 'app/state/user/state';
import { AccountState } from 'app/state/account/state';
import { TsSnackbarService } from 'tsui';
import { Account } from 'app/core/models';
import { AggregatedTasksService } from 'app/modules/aggregated-tasks/aggregated-tasks.service';
import { RelatedToType } from 'app/shared/enums';
import { Task, TaskStatus } from 'app/shared/modules/legacy-tasks/tasks.types';
import { FormFieldComponent } from '../form-field.types';
import { ProjectAddContactComponent } from 'app/modules/projects/modals/project-add-contact/project-add-contact.component';
import { ProjectAddCompanyComponent } from 'app/modules/projects/modals/project-add-company/project-add-company.component';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';
import { FileResource, FolderResource } from 'app/shared/modules/folders/folders.types';
import { FoldersTabViewFileComponent } from 'app/shared/modules/folders/view-file/view-file.component';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Project } from 'app/modules/projects/projects.types';
import { AddLinkToCustomObjectValueComponent } from 'app/shared/modules/custom-objects/modals/add-link-to-custom-object-value/add-link-to-custom-object-value.component';
import { ObjectsManagerService } from 'app/modules/settings/objects-manager/services/objects-manager.service';
import { CustomObject } from 'app/shared/modules/custom-objects/custom-objects.types';
import { FeatureFlagService } from 'app/core/services/feature-flag.service';
import { FeatureFlag } from 'app/core/enums';
import { ConfirmService } from '../../widgets/dialog/confirm/confirm.service';
import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';
import { AccountFoldersService } from 'app/modules/folders/folders.service';

const STANDARD_RELATED_TO_TYPES = [
  RelatedToType.Project,
  RelatedToType.Company,
  RelatedToType.Contact,
  RelatedToType.User,
  RelatedToType.FileResource,
  RelatedToType.Task,
  RelatedToType.Engagement,
];

@Component({
  templateUrl: './contact-form-field.component.html',
  styleUrls: ['./contact-form-field.component.scss'],
})
export class ContactFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  editing = false;
  value: any;
  customObjType: string;
  workflowStates: TaskStatus[];

  isNewModel = false;
  options: any[];
  filteredOptions: any[];
  account: Account;

  fileResource: FileResource;
  uploadingFile = false;

  isCustomObjectLookup = false;
  customObjectId: number;
  customObjects = this.store.selectSnapshot(ObjectsManagerState.get);
  multipleUserIds: number[];

  private _project: Project;
  private _originalValue: any;

  private get hasCustomObjectsEnabled$(): Observable<boolean> {
    return this.featureFlagService.hasFeatureFlag(FeatureFlag.CUSTOM_OBJECTS);
  }

  private get isRelatedToUser(): boolean {
    return this.fieldDef?.meta?.record_type === RelatedToType.User;
  }

  get isMultipleField(): boolean {
    return this.fieldDef?.meta?.multi_select;
  }

  get isReadOnly(): boolean {
    return this.fieldDef?.meta?.readonly || this.readonly;
  }

  private get isRelatedToTask(): boolean {
    return this.fieldDef?.related_to_type === RelatedToType.Task;
  }

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _matDialog: MatDialog,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _aggregatedTasksService: AggregatedTasksService,
    private readonly _accountFoldersService: AccountFoldersService,
    private readonly foldersTabService: FoldersTabService,
    private readonly projectsService: ProjectsService,
    private readonly snackBar: TsSnackbarService,
    private readonly objectsManagerService: ObjectsManagerService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly confirmService: ConfirmService,
    private readonly store: Store,
  ) {
    super();

    this.account = this.store.selectSnapshot(AccountState.getAccount);
    this._aggregatedTasksService.accountId = this.account.id;
    this.foldersTabService.accountId = this.account.id;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.value = this.model[this.fieldDef.name];
    if (this.isMultipleField && this.isRelatedToUser) {
      this.multipleUserIds = this.value?.map((val: string) => +val.split('|')[0]);
    }
    this.parseContactType();
    this._originalValue = this.value;

    this.isNewModel = this.model?.id == null;
    this.customObjType = this.fieldDef?.meta?.record_type;
    this.isCustomObjectLookup =
      (this.fieldDef?.name?.startsWith('cf_linked_') || this.isRelatedToCustomObject()) ?? false;

    this.handleCustomObjType();

    this.hasCustomObjectsEnabled$
      .pipe(
        untilComponentDestroyed(this),
        filter((hasCustomObjectsEnabled) => hasCustomObjectsEnabled),
        switchMap(() => this.objectsManagerService.get(Number(this.account.id))),
      )
      .subscribe((customObjects) => {
        this.handleCustomObjectId(customObjects);
      });
  }

  private isRelatedToCustomObject(): boolean {
    return !STANDARD_RELATED_TO_TYPES.includes(
      this.fieldDef?.meta?.record_type || this.fieldDef?.meta?.related_to_type,
    );
  }

  canShowAssociationButton(): boolean {
    return ((!this.value || this.value?.length === 0) && this.isMultipleField) || !this.isReadOnly;
  }

  private handleCustomObjType(): void {
    if (this.fieldDef?.meta?.related_to_type === RelatedToType.Task) {
      this.customObjType = RelatedToType.Task;
    }

    switch (this.customObjType) {
      case RelatedToType.User:
        this.options = this.store.selectSnapshot(UserState.getUsers);
        this.filteredOptions = this.options;
        break;

      case RelatedToType.FileResource:
        if (this.fieldDef.name in this.model && !this.isMultipleField) {
          this.setFolderSettings();

          const fieldValue = this.model[this.fieldDef.name]?.includes('|')
            ? this.model[this.fieldDef.name].split('|')[0]
            : this.model[this.fieldDef.name];

          if (fieldValue) {
            if (this.isRelatedToTask) {
              this.fileResource = this.model.file_resources.filter((file: FileResource) => file.id === fieldValue);
            } else {
              this.foldersTabService
                .getFile(this.model.root_folder_resource_id, fieldValue)
                .pipe(untilComponentDestroyed(this))
                .subscribe((fileResource) => {
                  this.fileResource = fileResource;
                });
            }
          }
        }

        break;

      case RelatedToType.Task:
        if (this.value) this.value = JSON.parse(String(this.value));
        this._aggregatedTasksService
          .getTaskStatuses()
          .pipe(untilComponentDestroyed(this))
          .subscribe((taskStatuses) => {
            this.workflowStates = taskStatuses;
          });
        break;

      case RelatedToType.Project:
        this.getProjectData();
        break;
    }
  }

  goToRecord(item?: any) {
    if (this.isDisabled) {
      return;
    }

    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    let vals = this.getValues();

    if (item) {
      vals = item.split('|');
    }

    if (this.customObjType == RelatedToType.Contact) {
      this._router.navigate(['/contacts', vals[0]]);
    } else if (this.customObjType == RelatedToType.Company) {
      this._router.navigate(['/companies', vals[0]]);
    } else if (this.customObjType == RelatedToType.Task) {
      this._router.navigate([
        '/projects',
        this.model.pipeline_id,
        this.value.project_id,
        'tasks',
        this.value.task_list_id,
        this.value.id,
      ]);
    } else if (this.isCustomObjectLookup) {
      if (this.customObjType == RelatedToType.Property) {
        this._router.navigate(['/properties', vals[0]]);
      } else if (this.customObjType == RelatedToType.Project) {
        this._router.navigate(['/projects', this._project?.pipeline_id, vals[0]]);
      } else {
        const customObject = this.customObjects.find((x) => x.name == this.customObjType);
        this._router.navigate(['/objects', customObject.id || this.data?.customObjectId, vals[0], 'details']);
      }
    } else {
      this.foldersTabService
        .getFile(this.model.root_folder_resource_id, vals[0])
        .pipe(untilComponentDestroyed(this))
        .subscribe((fileResource) => {
          this.viewFile(fileResource);
        });
    }
  }

  updateTask(task: Task): void {
    this._aggregatedTasksService.simpleUpdateTask(task).pipe(untilComponentDestroyed(this)).subscribe();
  }

  public openTypeRelatedModal(): void {
    const vals = this.getValues();
    let val;
    if (vals) {
      val = vals[0];
    }
    const data = {
      panelClass: 'mailbox-compose-dialog',
      data: {
        contactId: val,
        related_to_type: this.fieldDef.related_to_type,
        object: this.model,
        byPass: true,
        multi_select: this.fieldDef.meta.multi_select,
      },
    };
    const customObjectData = {
      data: {
        customObjectType: this.customObjType,
        customObjectId: this.customObjectId,
      },
    };

    let dialogRef: MatDialogRef<any>;
    if (this.customObjType == RelatedToType.Contact) {
      dialogRef = this._matDialog.open(ProjectAddContactComponent, data);
    } else if (this.customObjType == RelatedToType.Company) {
      dialogRef = this._matDialog.open(ProjectAddCompanyComponent, data);
    } else if (this.isCustomObjectLookup) {
      dialogRef = this._matDialog.open(AddLinkToCustomObjectValueComponent, customObjectData);
    }

    dialogRef
      .afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter((result) => !!result),
      )
      .subscribe((result) => {
        if (this.isMultipleField) {
          const value = this.value
            ? [...this.value, `${result?.contact?.id}|${result?.contact?.name}`]
            : [`${result?.contact?.id}|${result?.contact?.name}`];
          this.value = [...new Set(value)];
        } else {
          this.value = `${result?.contact?.id}|${result?.contact?.name}`;
        }
        this.onSave(this.value);

        // If this form is being used before a model has been saved i.e. the create form.
        if (this.isNewModel) {
          // JERRY FOR OPENAREA
          if (
            (this.account?.subdomain == 'openarea' || this.account?.subdomain == 'area') &&
            this.customObjType == RelatedToType.Company
          ) {
            this.update.emit({ ['title']: `${result?.contact?.name} Deal` });
          }
        }
      });
  }

  removeModelField(event: Event): void {
    event.stopPropagation();

    if (this.customObjType === RelatedToType.FileResource && !this.fileResource) {
      this.errorSnackBar();
      return;
    }

    if (this.isNewModel) {
      this.updateModel(null);
      this.update.emit({ [this.fieldDef.name]: null });
      this._changeDetectorRef.markForCheck();
      this.value = null;
    } else {
      this.confirmService
        .openConfirmationModal()
        .pipe(filter((result: boolean) => result))
        .subscribe(() => {
          this.updateModel(null);
          this.update.emit({ [this.fieldDef.name]: null });
          this._changeDetectorRef.markForCheck();
          this.value = null;
        });
    }
  }

  updateChangedModel(model: any): void {
    this.model = model;
    this.parseContactType();
    this._originalValue = this.model[this.fieldDef.name];
  }

  private getValues() {
    if (this.isNewModel) {
      return [this.model[this.fieldDef.name], this.value];
    } else {
      if (this.model[this.fieldDef.name] && typeof this.model[this.fieldDef.name] === 'string')
        return this.model[this.fieldDef.name].split('|');
      else return null;
    }
  }

  private parseContactType(): void {
    const customObjType = this.fieldDef?.meta?.record_type;
    const values = this.getValues();

    if (this.value && values && values.length == 2) {
      if (customObjType == RelatedToType.User) {
        this.value = values[0];
        this.value = parseInt(this.value);
      } else {
        this.value = values[1];
      }
    }
  }

  removeItem(item: string): void {
    this.value = this.value.filter((val) => val !== item);
    this.update.emit({ [this.fieldDef.name]: this.value });
    this.updateModel(this.value);
    this._changeDetectorRef.markForCheck();
  }

  private onSave(value: any, pushNewValue = true): void {
    if (this._originalValue != value) {
      if (this.isMultipleField && pushNewValue) {
        let newIds = this._originalValue?.map((val: string) => +val?.split('|')[0]) || [];

        if (
          this.customObjType === RelatedToType.FileResource ||
          this.customObjType === RelatedToType.Company ||
          this.customObjType === RelatedToType.Contact
        ) {
          newIds = this._originalValue;
        }

        if (newIds) {
          value = value instanceof Array ? [...newIds, ...value] : [...newIds, value];
        } else {
          value = value instanceof Array ? value : [value];
        }

        value = [...new Set(value)];
      }
      this.update.emit({ [this.fieldDef.name]: value });
      this.updateModel(value);
      this._changeDetectorRef.markForCheck();
    }
  }

  private updateModel(value: any): void {
    this.model[this.fieldDef.name] = value;
    this._originalValue = this.value;
  }

  filterQueryParams(): string | undefined {
    const value = this.getValues();

    if (!value) return undefined;

    let fjson = [[this.fieldDef.name, 'in', [[parseInt(value[0]), value[1]]]]];
    let queryFilter = {
      fjson: JSON.stringify(fjson),
      q: '',
    };

    return JSON.stringify(queryFilter);
  }

  edit(): void {
    if (!this.editing && !this.isReadOnly) {
      this.editing = true;
    }
  }

  onBlur($event: Event): void {
    this.editing = false;
  }

  onSelectionChange($event: MatSelectChange): void {
    this.onSave($event.value, false);
  }

  isFilterable(): boolean {
    return this.fieldDef.related_to_type === RelatedToType.Property;
  }

  hasNoRecordType(): boolean {
    return !this.fieldDef?.meta?.record_type;
  }

  private setFolderSettings(): void {
    if (this.data?.relatedToType === RelatedToType.Project) {
      this.foldersTabService.moduleId = this.data.project.id;
      this.foldersTabService.moduleBaseUrl = 'projects';
    } else if (this.data?.relatedToType === RelatedToType.Property) {
      this.foldersTabService.moduleId = this.data.property.property.id.toString();
      this.foldersTabService.moduleBaseUrl = 'properties';
    } else if (this.data?.relatedToType === RelatedToType.Contact) {
      this.foldersTabService.moduleId = this.data.contact.id.toString();
      this.foldersTabService.moduleBaseUrl = 'contacts';
    } else if (this.data?.relatedToType === RelatedToType.Company) {
      this.foldersTabService.moduleId = this.data.company.id.toString();
      this.foldersTabService.moduleBaseUrl = 'companies';
    } else if (this.data?.relatedToType === RelatedToType.Task) {
      this.foldersTabService.moduleId = this.model.id.toString();
      this.foldersTabService.moduleBaseUrl = 'tasks';
    }
  }

  onFileChanged(event: InputEvent): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    this.uploadingFile = true;

    let fileResource: FileResource;
    let createFileObservable: Observable<any>;
    let uploadDocumentObservable;

    if (this.isRelatedToTask) {
      fileResource = {
        related_to_type: RelatedToType.Task,
        related_to_id: this.model.id,
        name: file.name,
      };

      createFileObservable = this._accountFoldersService.getFolder().pipe(
        switchMap((folder: FolderResource) => {
          fileResource.folder_resource_id = folder.id;
          return this._accountFoldersService.createFile(folder.id, fileResource);
        }),
      );

      uploadDocumentObservable = (fileResource: FileResource) =>
        this._accountFoldersService.uploadDocument(fileResource.folder_resource_id, fileResource.id, file);
    } else {
      const folderId = this.model?.root_folder_resource_id;
      fileResource = {
        folder_resource_id: folderId,
        name: file.name,
      };

      createFileObservable = this.foldersTabService.createFile(folderId, fileResource);

      uploadDocumentObservable = (fileResource: FileResource) =>
        this.foldersTabService.uploadDocument(folderId, fileResource.id, file);
    }

    createFileObservable
      .pipe(
        untilComponentDestroyed(this),
        switchMap(uploadDocumentObservable),
        catchError(() => {
          this.snackBar.open('Unable to upload attachment at this moment. Please try again later.', 'error');
          return EMPTY;
        }),
        finalize(() => {
          this.uploadingFile = false;
          this._changeDetectorRef.detectChanges();
        }),
      )
      .subscribe((fileResource) => {
        this.fileResource = fileResource;

        if (this.isMultipleField) {
          const value = this.value
            ? [...this.value, `${fileResource.id}|${fileResource.name}`]
            : [`${fileResource.id}|${fileResource.name}`];
          this.value = [...new Set(value)];
        } else {
          this.value = `${fileResource.id}|${fileResource.name}`;
        }

        this.onSave(this.value);
        this.snackBar.open('Successfully uploaded attachment', 'success');
      });
  }

  viewFile(fileResource: FileResource): void {
    if (!this.value && this.readonly) {
      return;
    }

    if (!fileResource) {
      this.errorSnackBar();
      return;
    }

    this._matDialog.open(FoldersTabViewFileComponent, {
      panelClass: 'mailbox-compose-dialog',
      minWidth: '100vw',
      height: '100vh',
      data: {
        fileResource: fileResource,
      },
    });
  }

  downloadFile(file: FileResource): void {
    if (!file) {
      this.errorSnackBar();
      return;
    }

    window.open(file.document_url, '_blank');
  }

  private errorSnackBar(message: string = 'File not found'): void {
    this.snackBar.open(message, 'error', 'Close');
  }

  private getProjectData(): void {
    const vals = this.getValues();

    if (!vals) {
      return;
    }

    if (vals.filter((x) => !x).length > 0) {
      return;
    }

    this.projectsService
      .getProjectById(vals[0])
      .pipe(untilComponentDestroyed(this))
      .subscribe((project: Project) => {
        this._project = project;
      });
  }

  private handleCustomObjectId(customObjects: CustomObject[]): void {
    if (customObjects?.length) {
      this.customObjectId = customObjects.find(
        (customObject) => customObject.name?.toLowerCase() === this.customObjType?.toLowerCase(),
      )?.id;
    }
  }
}
