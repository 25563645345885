import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Contact, Country, ContactPagination, Category } from './contacts.types';
import { Project, ProjectPagination, Property } from 'app/modules/projects/projects.types';
import { LoanQuote } from '../projects/details/loans/loans.types';
import { Task } from 'app/shared/modules/legacy-tasks/tasks.types';
import { PropertyPagination } from '../properties/properties.types';
import {
  TaskExportFileResponse,
  TaskExportUtilsService,
} from '@shared/modules/legacy-tasks/services/task-export-utils.service';
import { generateDownloadableFile } from '@shared/functions';
import { FileFormat } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private _contact: BehaviorSubject<Contact | null>;
  private _contacts: BehaviorSubject<Contact[] | null>;
  private _pagination: BehaviorSubject<ContactPagination | null>;
  private _drawerToggle: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _importContacts: BehaviorSubject<any | null>;
  private _dealAssociatedContacts: BehaviorSubject<any | null>;
  private _dealAssociatedContactsPagination: BehaviorSubject<ContactPagination | null>;
  private _countries: BehaviorSubject<Country[] | null>;
  private _categories: BehaviorSubject<Category[] | null>;
  private _accountId: string;

  constructor(
    private _httpClient: HttpClient,
    private taskExportUtilsService: TaskExportUtilsService,
  ) {
    this._contact = new BehaviorSubject(null);
    this._contacts = new BehaviorSubject(null);
    this._importContacts = new BehaviorSubject(null);
    this._dealAssociatedContacts = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
    this._dealAssociatedContactsPagination = new BehaviorSubject(null);
    this._countries = new BehaviorSubject(null);
    this._categories = new BehaviorSubject(null);
  }

  get contact$(): Observable<Contact> {
    return this._contact.asObservable();
  }

  get contacts$(): Observable<Contact[]> {
    return this._contacts.asObservable();
  }

  get pagination$(): Observable<ContactPagination> {
    return this._pagination.asObservable();
  }

  get importContacts$(): Observable<Contact[]> {
    return this._importContacts.asObservable();
  }

  get dealAssociatedContacts$(): Observable<Contact[]> {
    return this._dealAssociatedContacts.asObservable();
  }

  get countries$(): Observable<Country[]> {
    return this._countries.asObservable();
  }

  get dealAssociatedContactsPagination$(): Observable<ContactPagination> {
    return this._dealAssociatedContactsPagination.asObservable();
  }

  get categories$(): Observable<Category[]> {
    return this._categories.asObservable();
  }

  get drawerToggle$(): Observable<boolean> {
    return this._drawerToggle.asObservable();
  }

  drawerToggle() {
    const newValue = !this._drawerToggle.value;
    this._drawerToggle.next(newValue);
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  getContactsByEmail(email: string): Observable<Contact[]> {
    return this._httpClient
      .get<{ results: Contact[] }>(`contacts?account_id=${this._accountId}`, {
        params: { email },
      })
      .pipe(map(({ results }) => results));
  }

  getContacts(
    page: number = 1,
    size: number = 20,
    sort = 'first_name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    csv = false,
    dontEmitValues = false,
  ): Observable<{ pagination: ContactPagination; contacts: Contact[] }> {
    const {
      name = '',
      email = '',
      phone = '',
      tags = '',
      category_name = '',
      job = '',
      company = '',
      associated_with_user_id = [],
    } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: ContactPagination; contacts: Contact[] }>(
        `contacts${csv ? '.csv' : ''}?account_id=${this._accountId}`,
        {
          params: {
            page: '' + page,
            per_page: '' + size,
            sort: sort,
            order: order,
            search: search,
            name: name,
            email: email,
            phone: phone,
            tags: tags?.length ? tags.join(',') : '',
            category_id: category_name,
            job_title: job,
            company: company,
            associated_with_user_id: associated_with_user_id?.length ? associated_with_user_id.join(',') : '',
          },
        },
      )
      .pipe(
        map((response: any) => {
          if (csv && response) {
            return response.data;
          }
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let contacts = response.results;
          if (!dontEmitValues) {
            this._pagination.next(pagination);
            this._contacts.next(contacts);
          }
          return {
            pagination,
            contacts,
          };
        }),
      );
  }

  search(
    filter: { fjson: string; sort?: string; order?: string; q?: string; project_id?: number; tags?: string },
    page: number,
    pageSize: any,
    csv = false,
    savedViewId?: number,
  ): Observable<{ results?: Contact[]; data?: string & { message: string } }> {
    if (page < 1) {
      page = 1;
    }

    if (!filter.project_id) {
      delete filter.project_id;
    }

    let params = {
      ...filter,
      page,
      per_page: pageSize,
    };

    if (savedViewId) {
      params = Object.assign(params, { saved_view_id: savedViewId });
    }

    return this._httpClient.post(`contacts/search${csv ? '.csv' : ''}?account_id=${this._accountId}`, params).pipe(
      tap((response: any) => {
        if (csv && response) {
          return response.data;
        }
        const pagination = {
          length: response.count,
          size: pageSize,
          page: page - 1,
          lastPage: response.previous,
          startIndex: page * pageSize,
          endIndex: Math.min(pageSize * (page + 1), response.count),
        };

        let contacts = response.results.map((result) => this.formatContactResponse(result));
        this._pagination.next(pagination);
        this._contacts.next(contacts);
      }),
    );
  }

  getDealAssociatedContacts(
    page: number = 1,
    size: number = 20,
    sort = 'first_name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    projectId: string,
    csv?: boolean,
  ): Observable<{ pagination: ContactPagination; contacts: Contact[] }> {
    const {
      name = '',
      email = '',
      phone = '',
      tags = '',
      category_name = '',
      job = '',
      company = '',
      associated_with_user_id = [],
    } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: ContactPagination; contacts: Contact[] }>(
        `contacts${csv ? '.csv' : ''}?account_id=${this._accountId}&project_id=${projectId}`,
        {
          params: {
            page: '' + page,
            per_page: '' + size,
            sort: sort,
            order: order,
            search: search,
            name: name,
            email: email,
            phone: phone,
            tags: tags?.length ? tags.join(',') : '',
            category_id: category_name,
            job_title: job,
            company: company,
            associated_with_user_id: associated_with_user_id?.length ? associated_with_user_id.join(',') : '',
          },
        },
      )
      .pipe(
        map((response: any) => {
          if (csv && response) {
            return response.data;
          }
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let contacts = response.results;
          this._dealAssociatedContactsPagination.next(pagination);
          this._dealAssociatedContacts.next(contacts);

          return {
            pagination,
            contacts,
          };
        }),
      );
  }

  searchContacts(query: string): Observable<Contact[] | null> {
    return this._httpClient
      .get<Contact[] | null>('api/apps/contacts/search', {
        params: { query },
      })
      .pipe(
        tap((contacts) => {
          this._contacts.next(contacts);
        }),
      );
  }

  getContactById(id: string): Observable<Contact> {
    return this._httpClient.get<any>(`contacts/${id}?account_id=${this._accountId}`).pipe(
      map((response) => {
        const contact = this.formatContactResponse(response.contact);

        this._contact.next(contact);
        return contact;
      }),
    );
  }

  createContact(contact: Contact): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .post<{ contact: Contact }>(`contacts?account_id=${this._accountId}`, { contact: contact })
          .pipe(
            map(({ contact }) => {
              if (contacts) {
                this._contacts.next([contact, ...contacts]);
              }

              return contact;
            }),
          ),
      ),
    );
  }

  createContacts(contactsMapping): Observable<any> {
    const uploadData = new FormData();
    for (let key of Object.keys(contactsMapping)) {
      const value = key === 'file' ? contactsMapping[key] : JSON.stringify(contactsMapping[key]);
      uploadData.append(key, value);
    }

    return this.importContacts$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient.post<any>(`contacts/import?account_id=${this._accountId}`, uploadData).pipe(
          map((reponse) => {
            if (reponse?.suggested_columns) {
              for (let key of Object.keys(reponse.suggested_columns)) {
                reponse.suggested_columns[key] = reponse.suggested_columns[key][0];
              }
            }
            this._importContacts.next(reponse);

            return reponse;
          }),
        ),
      ),
    );
  }

  updateContact(contact: Contact): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts: Array<Contact>) => {
        if (contact.category) {
          delete contact.category;
        }
        if (contact.category_name) {
          delete contact.category_name;
        }
        if (contact.contact_type) {
          delete contact.contact_type;
        }
        return this._httpClient
          .patch<{ contact: Contact }>(`contacts/${contact.id}?account_id=${this._accountId}`, {
            contact,
          })
          .pipe(
            map((response) => {
              let updatedContact = response.contact;

              if (contacts) {
                // Find the index of the updated contact
                const index = contacts.findIndex((item) => item.id === updatedContact.id);

                // Update the contact
                contacts[index] = updatedContact;

                // Update the contacts
                this._contacts.next(contacts);
              }
              this._contact.next(updatedContact);
              // Return the updated contact
              return updatedContact;
            }),
            switchMap((updatedContact) =>
              this.contact$.pipe(
                take(1),
                filter((item) => item && item.id === updatedContact.id),
                tap(() => {
                  // Update the contact if it's selected
                  this._contact.next(updatedContact);

                  // Return the updated contact
                  return updatedContact;
                }),
              ),
            ),
          );
      }),
    );
  }

  deleteContact(id: string): Observable<boolean> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient.delete(`contacts/${id}?account_id=${this._accountId}`).pipe(
          map((isDeleted: boolean) => {
            if (contacts) {
              // Find the index of the deleted contact
              const index = contacts.findIndex((item) => item.id === id);

              // Delete the contact
              contacts.splice(index, 1);

              // Update the contacts
              this._contacts.next(contacts);
            }

            // Return the deleted status
            return isDeleted;
          }),
        ),
      ),
    );
  }

  getCountries(): Observable<Country[]> {
    return this._httpClient.get<Country[]>('api/apps/contacts/countries').pipe(
      tap((countries) => {
        this._countries.next(countries);
      }),
    );
  }

  uploadAvatar(contactId: string, file: File): Observable<Contact> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this._httpClient
      .post<{ contact: Contact }>(`contacts/${contactId}/avatar?account_id=${this._accountId}`, uploadData)
      .pipe(
        map(({ contact }) => {
          this._contact.next(contact);
          return contact;
        }),
      );
  }

  deleteAvatar(contactId: string): Observable<Contact> {
    return this._httpClient
      .delete<{ contact: Contact }>(`contacts/${contactId}/avatar?account_id=${this._accountId}`)
      .pipe(
        map(({ contact }) => {
          this._contact.next(contact);
          return contact;
        }),
      );
  }

  /**
   * Get projects related to a contact
   */
  getProjects(
    contactId: string,
    page: number = 1,
    size: number = 10,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'desc',
  ): Observable<{ pagination: ProjectPagination; projects: Project[] }> {
    return this._httpClient
      .get<{ count: number; next: string; offset: number; previous: string; projects: Project[] }>(
        `contacts/${contactId}/projects?account_id=${this._accountId}`,
        {
          params: {
            page: page,
            per_page: size,
            sort: sort,
            order: order,
          },
        },
      )
      .pipe(
        map((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const projects = response.projects;

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          return {
            projects,
            pagination,
          };
        }),
      );
  }

  getProperties(
    contactId: string,
    page: number = 1,
    size: number = 10,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'desc',
  ): Observable<{ pagination: PropertyPagination; properties: Property[] }> {
    return this._httpClient
      .get<{ count: number; next: string; offset: number; previous: string; properties: Property[] }>(
        `contacts/${contactId}/properties?account_id=${this._accountId}`,
        {
          params: {
            page: page,
            per_page: size,
            sort: sort,
            order: order,
          },
        },
      )
      .pipe(
        map((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const properties = response.properties;

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          return {
            properties,
            pagination,
          };
        }),
      );
  }

  getTasksByContactId(contactId: string): Observable<Task[]> {
    return this._httpClient.get<{ tasks: Task[] }>(`contacts/${contactId}/tasks?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.tasks;
      }),
    );
  }

  exportContactTasks({ id, name, task_list }: Contact, format: 'csv' | 'pdf'): Observable<TaskExportFileResponse> {
    const params = new HttpParams()
      .set('account_id', this._accountId)
      .set('format', format)
      .set('select', TaskExportUtilsService.crmDefaultSelectColumns);

    return this._httpClient.get<TaskExportFileResponse>(`contacts/${id}/tasks`, { params }).pipe(
      tap(({ data }) => {
        this.taskExportUtilsService.handleTaskExport(data, format, name ? name + ' Tasks' : 'Contact Tasks');
      }),
    );
  }

  createTask(contactId: string, taskListId: number, task: Task): Observable<Task> {
    const body = { task };
    return this._httpClient
      .post<{ task: Task }>(`contacts/${contactId}/task_lists/${taskListId}/tasks?account_id=${this._accountId}`, body)
      .pipe(
        map((response) => {
          return response.task;
        }),
      );
  }

  getLoanQuotes(contactId: string): Observable<LoanQuote[]> {
    return this._httpClient
      .get<{ loan_quotes: LoanQuote[] }>(`contacts/${contactId}/loan_quotes?account_id=${this._accountId}`)
      .pipe(map(({ loan_quotes }) => loan_quotes));
  }

  sendMessage(contactId: string, subject: string, body: string): Observable<Contact> {
    return this._httpClient
      .post<any>(`contacts/${contactId}/send_message?account_id=${this._accountId}`, {
        message: body,
        subject: subject,
      })
      .pipe(
        map((reponse) => {
          // Return the contact
          return reponse.contact;
        }),
      );
  }

  private formatContactResponse(contact: Contact): Contact {
    return {
      ...contact,
      phone: this.normalizePhoneRelatedProp(contact.phone),
      ...(contact.mobile_phone ? { mobile_phone: this.normalizePhoneRelatedProp(contact.mobile_phone) } : {}),
    };
  }

  private normalizePhoneRelatedProp(value?: string): string {
    return value?.replace(/[^0-9]/g, '');
  }
}
