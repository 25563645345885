import { Pipeline } from 'app/modules/projects/projects.types';

export namespace Pipelines {
  export class GetGroups {
    static readonly type = 'Get Groups';

    constructor() {}
  }

  export class Get {
    static readonly type = 'Get Pipelines';

    constructor() {}
  }

  export class Set {
    static readonly type = 'Set Pipelines';

    constructor(public pipelines: Pipeline[]) {}
  }
}
