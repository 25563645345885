import { Injectable } from '@angular/core';
import { plural } from 'pluralize';

@Injectable({
  providedIn: 'root',
})
export class TasksBaseUrlService {
  constructor() {}

  getTasksUrl(relatedToType: string, relatedToTypeId: number): string {
    const pluralType = plural(relatedToType).toLowerCase();
    return `${pluralType}/${relatedToTypeId}`;
  }
}
