<ng-container>
  <div class="ts-table-container ag-theme-quartz">
    <ag-grid-angular
      [rowData]="rowData"
      [rowSelection]="rowSelection"
      [columnDefs]="colDefs"
      [suppressDragLeaveHidesColumns]="suppressDragLeaveHidesColumns"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [treeData]="treeData"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [getDataPath]="getDataPath"
      (cellClicked)="onCellClicked($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (sortChanged)="onSortChanged($event)"
      (gridReady)="onGridReady($event)"
      (columnMoved)="handleColumnMove($event)"
      (rowGroupOpened)="onRowGroupOpened($event)"
      (dragStopped)="onDragStopped($event)"
      (firstDataRendered)="firstDataRendered($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (gridColumnsChanged)="gridColumnsChanged.emit($event)"
      (columnResized)="onColumnResized($event)"
      [domLayout]="autoHeight ? 'autoHeight' : 'normal'"
      class="ag-theme-quartz"
      style="width: 100%; height: 100%"
    >
    </ag-grid-angular>
  </div>
</ng-container>
