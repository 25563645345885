import { ProjectTemplate } from '../projects/projects.types';
import { TaskList } from 'app/shared/modules/legacy-tasks/tasks.types';

export interface Address {
  address_string: string;
  city?: string;
  city_state_zip?: string;
  country?: string;
  full_address?: string;
  id: number;
  latitude: number;
  longitude: number;
  msa_name?: string;
  postal_code?: string;
  state?: string;
  street?: string;
}

export interface Company {
  address?: Address | any;
  address_string: string;
  custom_field_values: any[];
  id: string;
  name: string;
  website?: string | null;
  avatar_url?: string;
  tag_list: string[];
  created_at?: string;
  task_list?: TaskList;
  task_list_id?: number;
  template: ProjectTemplate<any>;
}

export interface Country {
  id: string;
  iso: string;
  name: string;
  code: string;
  flagImagePos: string;
}

export interface CompanyPagination {
  length: number;
  size: number;
  page: number;
  lastPage: number;
  startIndex: number;
  endIndex: number;
}

export interface Category {
  id: number;
  name: string;
  hidden?: boolean;
  rank?: number;
}

type CompanyStandardFieldsMap = { [index in keyof Company]: string };

export const CompanyStandardFieldsMap: CompanyStandardFieldsMap = Object.freeze({
  id: 'id',
  name: 'name',
  address: 'address',
  address_string: 'address_string',
  website: 'website',
  avatar_url: 'avatar_url',
  tag_list: 'tag_list',
  created_at: 'created_at',
  task_list: 'task_list',
  template: 'template',
  custom_field_values: 'custom_field_values',
});
