<div class="priority-and-due-date">
  <div class="due-date">
    <button
      mat-button
      class="min-w-26 z-0"
      [ngClass]="{ overdue: isOverdue(), 'due-set': !ignoreOverdue && value }"
      (click)="openDueDatePanel()"
      #dueDatePanelOrigin
    >
      <mat-icon [svgIcon]="'today'"></mat-icon>

      <span *ngIf="value">{{ value | date: 'shortDate' }}</span>
      <span *ngIf="!value">{{ notSetText }}</span>
    </button>
  </div>
  <ng-template #dueDatePanel>
    <div class="tasks-details-due-date-panel">
      <mat-calendar [selected]="value" [startAt]="value" (selectedChange)="updateDueDate($event)"></mat-calendar>

      <button mat-stroked-button *ngIf="value" (click)="removeDueDate()">
        <mat-icon [svgIcon]="'remove_circle'"></mat-icon>
        Remove {{ notSetText?.toLowerCase() }} date
      </button>
    </div>
  </ng-template>
</div>
