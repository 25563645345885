import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { FieldDef } from 'app/core/models';
import { tap } from 'rxjs';
import { StateBase } from '../state-base';
import { Pipeline } from 'app/modules/projects/projects.types';
import { Pipelines } from './action';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { ObjectGroupService } from '@services/object-group.service';

export interface PipelinesStateModel {
  pipelines: Pipeline[];
  groups: any[];
}

@State<PipelinesStateModel>({
  name: new StateToken('pipelinesState'),
  defaults: {
    pipelines: null,
    groups: null,
  },
})
@Injectable()
export class PipelinesState extends StateBase {
  protected projectsService = inject(ProjectsService);
  protected objectGroupService = inject(ObjectGroupService);

  constructor(protected readonly store: Store) {
    super(store);
  }

  @Selector()
  static getPipelines(state: PipelinesStateModel) {
    return state.pipelines;
  }

  @Selector()
  static getPipelinesGroups(state: PipelinesStateModel) {
    return state.groups;
  }

  @Action(Pipelines.Get)
  getPipelines(context: StateContext<PipelinesStateModel>) {
    return this.projectsService.getPipelinesWithoutCache(false).pipe(
      tap((pipelines) => {
        context.setState({ ...context.getState(), pipelines });
      }),
    );
  }

  @Action(Pipelines.Set)
  setPipelines(context: StateContext<PipelinesStateModel>, { pipelines }: { pipelines: Pipeline[] }) {
    context.setState({ ...context.getState(), pipelines });
  }

  @Action(Pipelines.GetGroups)
  getPipelinesGroups(context: StateContext<PipelinesStateModel>) {
    return this.objectGroupService.get(this.accountId, 'Pipeline').pipe(
      tap((groups) => {
        context.setState({ ...context.getState(), groups });
      }),
    );
  }
}
