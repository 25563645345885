import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { TsButtonModule, TSIconModule } from 'tsui';

@Component({
  standalone: true,
  imports: [MatIconModule, TSIconModule, TsButtonModule],
  template: ` <ts-button type="icon" color="accent" icon="open_in_new" (click)="onClick()"></ts-button> `,
})
export class TableOpenDetailsRendererComponent implements ICellRendererAngularComp {
  params;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onClick(): void {
    if (this.params && this.params.onOpenDetails) {
      this.params.onOpenDetails(this.params.data);
    }
  }
}
