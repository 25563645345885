import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable, of, switchMap, tap, zip } from 'rxjs';

import { StateBase } from 'app/state/state-base';
import {
  CustomObject,
  CustomObjectLinkHash,
  CustomObjectValue,
  CustomObjectValueResponse,
  CustomObjectValueTemplate,
  GetCustomObjectValueByIdFilter,
  GetCustomObjectValuesFilter,
} from '../custom-objects.types';
import { CustomObjectsService } from '../services/custom-objects.service';
import { CustomObjectActions } from './actions';
import { ObjectsManagerService } from 'app/modules/settings/objects-manager/services/objects-manager.service';
import { ProjectTemplateService } from 'app/modules/projects/project-template.service';
import { ProjectTemplate } from 'app/modules/projects/projects.types';
import GraphqlService from 'app/services/graphql.service';
import { GraphqlResultPageInfo } from 'app/shared/models/graphql';
import { generateDownloadableFile } from 'app/shared/functions';
import { TsSnackbarService } from 'tsui';

export interface CustomObjectStateModel {
  valuesData: CustomObjectValueResponse;
  selectedValue: CustomObjectValue;
  graphqlResults: { rows: any[]; pageInfo?: GraphqlResultPageInfo };
}

@State<CustomObjectStateModel>({
  name: new StateToken<CustomObjectStateModel>('customObjectState'),
  defaults: {
    selectedValue: null,
    valuesData: {
      values: [],
      pagination: null,
    },
    graphqlResults: null,
  },
})
@Injectable()
export class CustomObjectState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly customObjectsService: CustomObjectsService,
    private readonly objectsManagerService: ObjectsManagerService,
    private readonly projectTemplateService: ProjectTemplateService,
    private readonly graphqlService: GraphqlService,
    private readonly snackBar: TsSnackbarService,
  ) {
    super(store);
  }

  @Selector()
  static graphqlResults(state: CustomObjectStateModel) {
    return state.graphqlResults;
  }

  @Selector()
  static getValues(state: CustomObjectStateModel): CustomObjectValueResponse {
    return state.valuesData;
  }

  @Selector()
  static getValueById(state: CustomObjectStateModel): CustomObjectValue {
    return state.selectedValue;
  }

  @Action(CustomObjectActions.ClearSelectedValue)
  clearSelectedValue(context: StateContext<CustomObjectStateModel>) {
    const state = context.getState();
    context.setState({
      ...state,
      selectedValue: null,
    });
  }

  @Action(CustomObjectActions.GetValueById)
  getValueById(
    context: StateContext<CustomObjectStateModel>,
    { filter: { id, customObjectId } }: { filter: GetCustomObjectValueByIdFilter },
  ) {
    return this.customObjectsService.getValueById(id, customObjectId, Number(this.account.id)).pipe(
      switchMap((customObjectValue) => {
        return zip(
          of(customObjectValue),
          this.objectsManagerService.getById(Number(this.account.id), customObjectId),
          this.objectsManagerService.get(Number(this.account.id)),
        );
      }),
      tap(([customObjectValue, customObject, customObjects]) => {
        const customObjectLinks = (
          customObjects.find(({ id }) => customObject.id === id)?.custom_object_links || []
        ).map((link) => ({
          ...link,
          customObject: customObjects.find((y) => y.id === Number(Object.keys(link)[0])),
        })) as Array<CustomObjectLinkHash & { customObject?: CustomObject }>;

        const state = context.getState();

        context.setState({
          ...state,
          selectedValue: {
            ...customObjectValue,
            data: {
              ...customObjectValue.data,
              id: customObjectValue.id,
            },
            customObject: {
              ...customObject,
              custom_object_links: customObjectLinks,
            },
          },
        });
      }),
    );
  }

  @Action(CustomObjectActions.UpdateCustomObjectValueTemplateDefinition)
  updateTemplateDefinition(
    context: StateContext<CustomObjectStateModel>,
    { template }: { template: ProjectTemplate<CustomObjectValueTemplate> },
  ) {
    return this.projectTemplateService
      .update<CustomObjectValueTemplate>({ ...template, account_id: Number(this.account.id) })
      .pipe(
        tap(() => {
          const state = context.getState();
          this.store.dispatch(
            new CustomObjectActions.GetValueById({
              id: state.selectedValue.id,
              customObjectId: state.selectedValue.customObject.id,
            }),
          );
        }),
      );
  }

  @Action(CustomObjectActions.AddAndUpdateCustomObjectValueTemplate)
  addAndUpdateTemplate(
    context: StateContext<CustomObjectStateModel>,
    { value }: { value: Partial<CustomObjectValue> },
  ) {
    const account_id = Number(this.account.id);
    const state = context.getState();

    return this.projectTemplateService
      .create<CustomObjectValueTemplate>({
        ...value.template,
        id: undefined,
        rank: undefined,
        account_id,
      })
      .pipe(
        switchMap((template) =>
          this.customObjectsService.updateValue(Number(this.account.id), value.customObject.id, value.id, {
            template_id: template.id,
          }),
        ),
        tap(() => {
          this.store.dispatch(
            new CustomObjectActions.GetValueById({
              id: state.selectedValue.id,
              customObjectId: state.selectedValue.customObject.id,
            }),
          );
        }),
      );
  }

  @Action(CustomObjectActions.UpdateCustomObjectValueTemplateId)
  updateTemplateId(context: StateContext<CustomObjectStateModel>, { value }: { value: Partial<CustomObjectValue> }) {
    return this.customObjectsService
      .updateValue(Number(this.account.id), value.customObject.id, value.id, { template_id: value.template_id })
      .pipe(
        tap(() => {
          this.store.dispatch(
            new CustomObjectActions.GetValueById({
              id: value.id,
              customObjectId: value.customObject.id,
            }),
          );
        }),
      );
  }

  @Action(CustomObjectActions.GetValues)
  getCustomObjectValues(
    context: StateContext<CustomObjectStateModel>,
    { filter }: { filter: GetCustomObjectValuesFilter },
  ): Observable<CustomObjectValueResponse> {
    return this.customObjectsService
      .getValues(
        Number(this.account.id),
        filter.customObjectId,
        filter.relatedToType,
        filter.relatedToId,
        filter.page,
        filter.size,
        filter.sort,
        filter.order,
      )
      .pipe(
        tap((response) => {
          const state = context.getState();
          context.setState({ ...state, valuesData: response });
        }),
      );
  }

  @Action(CustomObjectActions.Graphql.Action)
  graphql(
    context: StateContext<CustomObjectStateModel>,
    { graphqlParams, hiddenFilter }: { graphqlParams: CustomObjectActions.Graphql.Params; hiddenFilter: any[][] },
  ) {
    const filter = { ...graphqlParams.filter };
    if (hiddenFilter?.length) {
      const fjson = [...JSON.parse(graphqlParams.filter.fjson || '[]'), ...hiddenFilter];
      filter.fjson = JSON.stringify(fjson);
    }

    return this.graphqlService
      .query(
        Number(this.account.id),
        {
          connection: graphqlParams.connection,
          fieldDefs: graphqlParams.fieldDefs || [],
          fields: ['created_at', 'updated_at', ...graphqlParams.fields.filter((field) => field !== 'id'), 'id'],
          filter,
        },
        graphqlParams.exportOptions,
      )
      .pipe(
        tap((result) => {
          if ((result as any)?.message) {
            this.snackBar.open((result as any).message, 'info');
            return;
          }

          if (graphqlParams.exportOptions) {
            generateDownloadableFile(
              result as any,
              graphqlParams.exportOptions.format,
              graphqlParams.exportOptions.fileName,
            );
          } else {
            const { edges, pageInfo } = result;
            const state = context.getState();

            context.setState({
              ...state,
              graphqlResults: {
                rows: edges.map(({ node }) => node),
                pageInfo,
              },
            });
          }
        }),
      );
  }

  @Action(CustomObjectActions.CreateValue)
  createCustomObjectValue(
    context: StateContext<CustomObjectStateModel>,
    { customObjectId, value }: { customObjectId: number; value: CustomObjectValue },
  ): Observable<CustomObjectValue> {
    return this.customObjectsService.createValue(Number(this.account.id), customObjectId, value);
  }

  @Action(CustomObjectActions.UpdateSelectedValue)
  updateSelectedValue(
    context: StateContext<CustomObjectStateModel>,
    { value }: { value: Partial<CustomObjectValue> },
  ): Observable<CustomObjectValue> {
    return this.customObjectsService.updateValue(Number(this.account.id), value.customObject.id, value.id, {
      data: value.data,
    });
  }

  @Action(CustomObjectActions.SetSelectedValue)
  setSelectedValue(context: StateContext<CustomObjectStateModel>, { value }: { value: CustomObjectValue }): void {
    context.patchState({ selectedValue: value });
  }

  @Action(CustomObjectActions.UpdateValue)
  updateCustomObjectValue(
    context: StateContext<CustomObjectStateModel>,
    {
      customObjectId,
      customObjectValueId,
      value,
    }: { customObjectId: number; customObjectValueId: number; value: CustomObjectValue },
  ): Observable<CustomObjectValue> {
    return this.customObjectsService
      .updateValue(Number(this.account.id), customObjectId, customObjectValueId, value)
      .pipe(
        tap((response) => {
          const state = context.getState();
          const valuesData = { ...state.valuesData };
          const index = valuesData.values.findIndex((v) => v.id === response.id);
          valuesData.values[index] = response;
          context.setState({ ...state, valuesData });
        }),
      );
  }

  @Action(CustomObjectActions.DeleteValue)
  deleteCustomObjectValue(
    context: StateContext<CustomObjectStateModel>,
    { customObjectId, customObjectValueId }: { customObjectId: number; customObjectValueId: number },
  ): Observable<void> {
    return this.customObjectsService.deleteValue(Number(this.account.id), customObjectId, customObjectValueId);
  }

  @Action(CustomObjectActions.UploadValueImage)
  uploadImage(ctx: StateContext<CustomObjectStateModel>, action: CustomObjectActions.UploadValueImage) {
    return this.customObjectsService
      .uploadValueImage(Number(this.account.id), action.customObjectValueId, action.file)
      .pipe(
        tap((response) => {
          const state = ctx.getState();
          ctx.setState({
            ...state,
            selectedValue: {
              ...state.selectedValue,
              images: [...(state.selectedValue?.images || []), response],
            },
          });
        }),
      );
  }

  @Action(CustomObjectActions.DeleteValueImage)
  deleteImage(ctx: StateContext<CustomObjectStateModel>, action: CustomObjectActions.DeleteValueImage) {
    return this.customObjectsService.deleteValueImage(Number(this.account.id), action.imageId).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          selectedValue: {
            ...state.selectedValue,
            images: state.selectedValue?.images.filter((img) => img.id !== action.imageId),
          },
        });
      }),
    );
  }
}
