import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

import { TsSnackbarComponent, DEFAULT_CONFIG, TsSnackbarData, panelClasses } from '../ts-snackbar';

@Injectable({
  providedIn: 'root',
})
export class TsSnackbarService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public open(
    message: string,
    type: panelClasses = 'info',
    action: string = null,
    config: MatSnackBarConfig = DEFAULT_CONFIG,
  ): MatSnackBarRef<TsSnackbarComponent> {
    return this.snackBar.openFromComponent(TsSnackbarComponent, {
      data: {
        message,
        actionText: action,
        type,
      } as TsSnackbarData,
      panelClass: `ts-snackbar__${type}`,
      ...config,
    });
  }

  public openApplyChanges(
    onReset?: () => void,
    onSave?: () => void,
    message = 'Changes were applied to the view',
  ): MatSnackBarRef<TsSnackbarComponent> {
    return this.snackBar.openFromComponent(TsSnackbarComponent, {
      data: {
        message,
        type: 'default',
        actionText: 'confirm-changes',
        closeButton: true,
        onReset,
        onSave,
      } as TsSnackbarData,
      panelClass: `ts-snackbar__default`,
      ...DEFAULT_CONFIG,
      duration: 0,
    });
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }
}
