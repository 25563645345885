import { ChartType } from 'app/shared/components/chart/chart.types';
import { RelatedToType } from 'app/shared/enums';
import { ReportType, ReportTypeQueryParamValues } from '../reports/reports.types';
import { GraphqlFilter, GraphqlFilterSort, GraphqlResultPageInfo } from 'app/shared/models/graphql';

export type ReportLayoutQueryParams = Partial<{
  account_id: number;
  page: number;
  per_page: number;
  report_types: ReportTypeQueryParamValues[];
  query?: string;
  created_by_me?: boolean;
  favorites?: boolean;
}>;

export interface ReportGraphqlFilter extends GraphqlFilter {
  timeSeries?: TimeSeriesFilter;
}

export interface CreateReportModel {
  name: string;
  relatedToType: RelatedToType;
  report_type: ReportType;
}

export interface ReportLayoutDefinition {
  related_to_type: RelatedToType;
  visualizations: Partial<ReportLayoutChart>[];
  columns: string[];
  cadence?: string;
  groupBy?: GraphqlFilterSort[];
  summarizeMethod?: SummarizeMethod;
  trailInfo?: { target_field: string; related_to_type: RelatedToType };
}

export interface ListFilterItem {
  id: string;
  filter: string;
  value: any;
  alternate_value: any;
  type: string;
}

export interface ReportLayoutChart {
  title: string;
  chart_type: ChartType;
  summary_value: string;
  summary_type: string;
  group_by: string;
  series_value?: string;
  data: any;
  id: string;
  series?: any[];
  rankedCategories?: any[];
  formatting?: any;
  filters?: ReportLayoutChartFilter[];
  isTimeSeries?: boolean;
  display_fields?: string[];
}

export interface ReportLayoutChartFilter {
  category: string;
  key: string;
  value: any;
}

export interface DrillFilter {
  [dataPointIndex: number]: [string, string, any];
}

type ReportLayoutRelatedToTypeAvailable = RelatedToType.Project | RelatedToType.CustomObject;

export interface ReportLayoutRelatedToType {
  type: ReportLayoutRelatedToTypeAvailable;
  label: string;
  time_series: boolean;
}

export interface TimeSeriesFilter {
  startDate: string;
  endDate: string;
}

export interface ReportFilterEventArgs {
  timeSeriesFilter: TimeSeriesFilter;
  listFilterItems: ListFilterItem[];
}

export enum TimeSeriesCadenceOptions {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export enum SummarizeMethod {
  SUM = 'SUM',
  AVG = 'AVG',
}

export type DataTableModel = {
  rows: any[];
  pageInfo?: GraphqlResultPageInfo;
  timeSeries?: {
    columns: string[];
    isGrouped?: boolean;
  };
};

export type ReportLayoutSummaryFormula = {
  _destroy: boolean;
  id: number;
  report_layout_id: number;
  field: string;
  summary_formula_id: number;
};
