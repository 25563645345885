<div class="ts-datepicker">
  <input
    [min]="min"
    [max]="max"
    [disabled]="true"
    type="text"
    [placeholder]="placeholder || 'MM/DD/YYYY'"
    [formControl]="control"
    [matDatepicker]="picker"
  />
  <mat-datepicker-toggle matDatepickerToggleIcon="caret" matSuffix disableRipple="true" [for]="picker">
    <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
  </mat-datepicker-toggle>

  <mat-datepicker
    [startView]="mode"
    yPosition="below"
    #picker
    (monthSelected)="setMonthAndYear($event, picker)"
  ></mat-datepicker>
</div>
