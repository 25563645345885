import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'project-dialog-comments-panel',
  templateUrl: './comments.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDialogCommentsPanelComponent {
  @Input() project: any;
  @Input() account: any;
}
