import { Component, Inject, Input, Optional } from '@angular/core';
import { ControlContainer, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseFormDirective } from '../directives/base-form.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { DatePipe } from '@angular/common';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import moment, { Moment } from 'moment';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

class DynamicDateFormat {
  mode: 'month' | 'year' | 'multi-year';

  get parse() {
    if (this.mode !== 'multi-year') {
      return {
        dateInput: 'MM/DD/YYYY',
      };
    } else {
      return {
        dateInput: 'MM/YYYY',
      };
    }
  }

  get display() {
    if (this.mode !== 'multi-year') {
      return {
        dateInput: 'MM/DD/YYYY', // Display format on the input field
        monthYearLabel: 'MMMM YYYY', // Format for the month and year in the datepicker
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      };
    } else {
      return {
        dateInput: 'MM/YYYY', // Display format on the input field
        monthYearLabel: 'MM YYYY', // Format for the month and year in the datepicker
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MM YYYY',
      };
    }
  }
}

@Component({
  selector: 'ts-datepicker',
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
    DatePipe,
    MatDatepickerModule,
    MatFormFieldModule,
  ],
  templateUrl: './ts-datepicker.component.html',
  styleUrl: './ts-datepicker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TsDatepickerComponent,
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useClass: DynamicDateFormat },
  ],
})
export class TsDatepickerComponent extends BaseFormDirective {
  @Input() placeholder: string;
  @Input() min: string; // Date in ISO format
  @Input() max: string; // Date in ISO format
  @Input() mode: 'month' | 'year' | 'multi-year' = 'month';

  constructor(
    @Optional() protected controlContainer: ControlContainer,
    @Inject(MAT_DATE_FORMATS) protected dateFormat: DynamicDateFormat,
  ) {
    super(controlContainer);
  }

  ngOnInit() {
    this.dateFormat.mode = this.mode;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    if (this.mode === 'multi-year') {
      const ctrlValue = this.control?.value || moment();

      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());

      this.control.setValue(ctrlValue);
      this.control.markAsDirty();
      this.control.updateValueAndValidity();
      this.control.markAsTouched();

      datepicker.close();
    }
  }
}
