<div class="container" [formGroup]="form">
  <div class="container__prefix">
    <ng-content select="[prefix]"></ng-content>
  </div>
  <div class="container__field">
    <ts-select
      [items]="fields"
      itemKey="name"
      itemLabel="label"
      [hasGroups]="hasGroups"
      formControlName="fieldName"
      [uiOptions]="{
        roundedBorders: false,
        placeholder: 'field name',
        searchPlaceholder: 'Field name...',
        size: 'medium',
      }"
    >
    </ts-select>
  </div>
  <div class="container__operator">
    <ts-select
      [items]="
        (filterValue?.fieldName | tsFieldFromName: fields)?.meta.filtering.operators
          | tsMutateToAny: mutateOperatorListFn
      "
      itemKey="name"
      itemLabel="label"
      formControlName="operator"
      [disabled]="!filterValue?.fieldName"
      [uiOptions]="{
        roundedBorders: false,
        placeholder: 'operation',
        searchPlaceholder: 'Operation...',
        size: 'small',
      }"
    >
    </ts-select>
  </div>
  <div class="container__value">
    <ng-container *ngIf="filterValue?.fieldName | tsFieldFromName: fields as field; else placeholderField">
      <ng-container [ngSwitch]="field?.type">
        <ng-container *ngSwitchCase="'select'">
          <mat-form-field>
            <mat-select
              #select
              formControlName="value"
              multiple
              (closed)="onValueSelectBlur(select)"
              placeholder="value"
            >
              <ng-container *ngFor="let item of field?.meta.options">
                <mat-option *ngIf="item | TsIsObject; else itemIsString" [value]="item.value">
                  {{ item.label }}
                </mat-option>
                <ng-template #itemIsString>
                  <mat-option [value]="item">{{ item }}</mat-option>
                </ng-template>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <mat-form-field>
            <mat-select
              #selectCheckbox
              formControlName="value"
              multiple
              (closed)="onValueSelectBlur(selectCheckbox)"
              placeholder="value"
            >
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'decimal'">
          <mat-form-field appearance="fill">
            <input
              matInput
              type="text"
              formControlName="value"
              placeholder="value"
              [mask]="getMaskForNumberDecimal(field)"
              (blur)="onValueInputBlur($event)"
            />
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          <mat-form-field appearance="fill">
            @if (field.name === 'id' || field.name.endsWith('.id')) {
            <input matInput type="text" formControlName="value" placeholder="value" (blur)="onValueInputBlur($event)" />
            } @else {
            <input
              matInput
              type="text"
              formControlName="value"
              placeholder="value"
              [mask]="getMaskForNumberDecimal(field)"
              (blur)="onValueInputBlur($event)"
            />
            }
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'money'">
          <mat-form-field appearance="fill">
            <input
              matInput
              currencyMask
              formControlName="value"
              placeholder="value"
              [options]="{ align: 'left', precision: field.meta?.scale || 0, prefix: '$' }"
              (blur)="onValueInputBlur($event)"
            />
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'percent'">
          <mat-form-field appearance="fill">
            <input
              matInput
              type="text"
              mask="separator.2"
              formControlName="value"
              placeholder="value"
              [allowNegativeNumbers]="true"
              separatorLimit="100"
              (blur)="onValueInputBlur($event)"
            />
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'datetime'">
          <mat-form-field *ngIf="operator.value === 'is'; else defaultDateFilter" appearance="fill">
            <mat-select
              #dateIsSelect
              formControlName="value"
              (closed)="onValueSelectBlur(dateIsSelect)"
              placeholder="value"
            >
              <mat-option
                *ngFor="let item of (filterValue?.fieldName | tsFieldFromName: fields)?.meta.filtering.functions"
                [value]="item"
              >
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-template #defaultDateFilter>
            <mat-form-field class="datetime" appearance="fill">
              <input
                matInput
                readonly
                [matDatepicker]="datePicker"
                formControlName="value"
                placeholder="value"
                (dateChange)="onValueDateBlur($event)"
                (click)="datePicker.open()"
              />
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-error> Date should be in MM/DD/YYYY format.</mat-error>
            </mat-form-field>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          <mat-form-field *ngIf="operator.value === 'is'; else defaultDateFilter" appearance="fill">
            <mat-select #dateIsSelect formControlName="value" (closed)="onValueSelectBlur(dateIsSelect)">
              <mat-option
                *ngFor="let item of (filterValue?.fieldName | tsFieldFromName: fields)?.meta.filtering.functions"
                [value]="item"
              >
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-template #defaultDateFilter>
            <mat-form-field class="datetime" appearance="fill">
              <input
                matInput
                readonly
                [matDatepicker]="datePicker"
                formControlName="value"
                placeholder="value"
                (dateChange)="onValueDateBlur($event)"
                (click)="datePicker.open()"
              />
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-error> Date should be in MM/DD/YYYY format.</mat-error>
            </mat-form-field>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'record'">
          <mat-form-field>
            <mat-select
              #recordSelect
              formControlName="value"
              multiple
              (closed)="onValueSelectBlur(recordSelect)"
              (opened)="onValueSelectOpen(recordSelect)"
              (selectionChange)="onValueSelectChange($event)"
              placeholder="value"
            >
              <mat-form-field class="sticky top-0 z-10 w-full p-2 pb-0">
                <input matInput placeholder="Search..." autocomplete="off" [formControl]="recordSearch" />
              </mat-form-field>
              <mat-option *ngIf="lookupLoading | async; else placeholderOption" class="no-checkbox" disabled>
                <mat-progress-spinner class="spinner" diameter="24" mode="indeterminate"> </mat-progress-spinner>
              </mat-option>
              <ng-template #placeholderOption>
                <mat-option class="no-checkbox h-0" disabled> </mat-option>
              </ng-template>
              <mat-option *ngFor="let result of selectedLookupResults" [value]="result.id">{{
                result.label
              }}</mat-option>
              <mat-option
                *ngFor="let result of lookupResults | async | tsFilterBy: []:selectedLookupResults:hideSelected"
                [value]="result.id"
                >{{ result.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-form-field appearance="fill">
            <input matInput formControlName="value" (blur)="onValueInputBlur($event)" placeholder="value" />
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #placeholderField>
      <mat-form-field appearance="fill">
        <input matInput disabled placeholder="value" />
      </mat-form-field>
    </ng-template>
  </div>
  <div class="container__suffix">
    <ts-button type="icon" icon="ts_trash-alt" iconColor="gray" (click)="filterRemoved.emit(index)"></ts-button>
  </div>
</div>
