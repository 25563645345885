import { Pipe, PipeTransform } from '@angular/core';
import { FieldDef } from '@core/models';

@Pipe({
  name: 'fieldSearch',
})
export class FieldSearchPipe implements PipeTransform {
  transform(items: Array<FieldDef>, searchTxt: string): any[] {
    if (!items || !items.length) return items;
    if (!searchTxt || !searchTxt.length) return items;
    return items.filter((item) => item.label.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1);
  }
}
