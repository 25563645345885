import { Task } from './tasks.types';
export namespace TaskUtils {
  export const deepFindTaskById = (tasks: Task[], parentId: number): Task | undefined => {
    for (const task of tasks) {
      if (task.id === parentId) {
        return task;
      }
      if (task.children) {
        const found = deepFindTaskById(task.children, parentId);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  export const deepUpdateTaskById = (tasks: Task[], updatedTask: Task): Task[] => {
    return tasks.map((task) => {
      if (task.id === updatedTask.id) {
        return { ...task, ...updatedTask };
      }

      if (task.children) {
        return { ...task, children: deepUpdateTaskById(task.children, updatedTask) };
      }

      return task;
    });
  };

  export const deepSortTasksByRank = (tasks: Task[]): Task[] => {
    tasks?.sort((a, b) => a.rank - b.rank);
    return (
      tasks?.map((task) => {
        if (task.children) {
          task.children?.sort((a, b) => a.rank - b.rank);
          deepSortTasksByRank(task.children);
        }
        return task;
      }) ?? []
    );
  };

  export const flattenTasks = (tasks: Task[]): Task[] => {
    return (
      tasks?.reduce((acc, task) => {
        if (task?.children) {
          acc?.push(task, ...flattenTasks(task?.children));
        } else {
          acc?.push(task);
        }
        return acc;
      }, [] as Task[]) ?? []
    );
  };
}
