import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { cloneDeep, cloneDeepWith, noop } from 'lodash';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { TasksBaseUrlService } from '../services/tasks-base-url.service';
import { Task, TaskList, TaskModuleRelatedToType, WorkflowState, TaskType, Todo } from '../tasks.types';
import { TaskUtils } from '../tasks.utils';
import { TaskActions } from './actions';
import { Approval } from '@shared/modules/approvals/approvals.types';
import { User } from '@core/models';
import { Contact } from 'app/modules/contacts/contacts.types';
import { patch } from '@ngxs/store/operators';
import { LegacyTasksTabService } from '../services/tasks.service';

export interface TaskStateModel {
  apiBaseUrl: string;
  relatedToType: TaskModuleRelatedToType;
  relatedToTypeId: number;
  tasks: Task[];
  searchedIds: number[];
  taskList: TaskList;
  currentTask: Task;
}

@State<TaskStateModel>({
  name: new StateToken<TaskStateModel>('taskState'),
  defaults: {
    relatedToType: null,
    relatedToTypeId: null,
    tasks: [],
    searchedIds: null,
    taskList: null,
    apiBaseUrl: '',
    currentTask: null,
  },
})
@Injectable()
export class TaskState {
  constructor(
    private taskBaseUrlService: TasksBaseUrlService,
    private taskService: LegacyTasksTabService,
  ) {}

  @Action(TaskActions.SetRelatedTo)
  setRelatedToType(context: StateContext<TaskStateModel>, { relatedToType, relatedToTypeId }: TaskStateModel) {
    const apiBaseUrl = this.taskBaseUrlService.getTasksUrl(relatedToType, relatedToTypeId);
    context.setState({
      tasks: [],
      taskList: null,
      currentTask: null,
      searchedIds: null,
      relatedToType,
      relatedToTypeId,
      apiBaseUrl,
    });
  }

  @Selector()
  static getTaskRelatedTo(state: TaskStateModel): { relatedToType: TaskModuleRelatedToType; relatedToTypeId: number } {
    return cloneDeep({ relatedToType: state.relatedToType, relatedToTypeId: state.relatedToTypeId });
  }

  @Selector()
  static getApiBaseUrl(state: TaskStateModel): string {
    return state.apiBaseUrl;
  }

  // =========================
  // TASK

  @Selector()
  static getCurrentTask(state: TaskStateModel): Task {
    return state?.currentTask;
  }

  @Action(TaskActions.GetTaskDetail)
  getTaskById(context: StateContext<TaskStateModel>, { taskListId, taskId }: { taskListId: number; taskId: number }) {
    return this.taskService.getTaskDetailById(taskListId, taskId).pipe(
      tap((task) => {
        task.children = TaskUtils.deepSortTasksByRank(task.children);
        task.todos = task.todos?.sort((a, b) => a.rank - b.rank);
        context.patchState({ currentTask: task });
      }),
    );
  }

  @Action(TaskActions.DuplicateTask)
  duplicateTask(
    context: StateContext<TaskStateModel>,
    { taskListId, task, templateId }: { taskListId: number; task: Task; templateId: number },
  ) {
    return this.taskService
      .duplicateTask(taskListId, templateId, task)
      .pipe(switchMap(() => context.dispatch(new TaskActions.RecalculateTaskListDates(taskListId))));
  }

  @Action(TaskActions.ClearCurrentTask)
  clearCurrentTask(context: StateContext<TaskStateModel>) {
    context.patchState({ currentTask: null });
  }

  @Action(TaskActions.CreateTask)
  createTask(
    context: StateContext<TaskStateModel>,
    { model }: { model: { taskType: TaskType; parentId?: number; rank?: number } },
  ) {
    let currentTasks = cloneDeep(context.getState().tasks);
    let currentTaskList = cloneDeep(context.getState().taskList);

    const task = {
      task_type: model.taskType,
      task_list_id: currentTaskList.id,
      rank: model.rank || 0, // TODO MJR, check the scenario where rank is undefined
      parent_id: model.parentId,
    };

    return this.taskService.createTask(task.task_list_id, task, null, null, true, false).pipe(
      tap((newTask: Task) => {
        if (newTask.parent_id) {
          const parentTask = TaskUtils.deepFindTaskById(currentTasks, newTask.parent_id);
          parentTask.children = [...parentTask.children, newTask];
          currentTasks = TaskUtils.deepUpdateTaskById(currentTasks, parentTask);
        } else {
          currentTasks.push(newTask);
        }

        context.patchState({
          tasks: currentTasks,
          currentTask: newTask,
        });
      }),
    );
  }

  @Action(TaskActions.CreateSubTask)
  createSubTask(context: StateContext<TaskStateModel>) {
    const nextCurrentTaskRank = () => {
      const currentTask = context.getState().currentTask;
      if (!currentTask.children || currentTask.children.length === 0) {
        return 1;
      }

      return Math.max(...currentTask.children.map((child) => child.rank)) + 1;
    };

    const currentTask = { ...context.getState().currentTask };

    let subtask = {
      task_type: currentTask.task_type,
      task_list_id: currentTask.task_list_id,
      rank: nextCurrentTaskRank(),
      parent_id: currentTask.id,
    };

    return this.taskService.createTask(currentTask.task_list_id, subtask, null, true).pipe(
      tap((task) => {
        currentTask.children = [...currentTask.children, task];
        const tasks = TaskUtils.deepUpdateTaskById(context.getState().tasks, currentTask);
        context.patchState({ currentTask, tasks });
      }),
    );
  }

  @Action(TaskActions.UpdateTask, { cancelUncompleted: true })
  updateTask(context: StateContext<TaskStateModel>, { task, templateId }: { task: Task; templateId: number }) {
    const state = context.getState();
    const currentTask = state.currentTask;
    const prevTaskVersion = TaskUtils.deepFindTaskById(state.tasks, task.id);

    return this.taskService.updateTaskById(task, templateId)?.pipe(
      tap((task) => {
        task.children = TaskUtils.deepSortTasksByRank(task.children);
        task.todos = task.todos?.sort((a, b) => a.rank - b.rank);

        const newTasksArray = cloneDeepWith(state.tasks, (value) => {
          if (value?.id === task.id && value?.task_type === task.task_type) {
            return task;
          }
        });

        const newState = {
          tasks: newTasksArray,
        };

        if (task.parent_id === currentTask?.id) {
          currentTask.children = TaskUtils.deepUpdateTaskById(currentTask.children, task);
          newState['currentTask'] = currentTask;
        }

        if (task.id === currentTask?.id) {
          newState['currentTask'] = task;
        }

        context.patchState(newState);
        if (!prevTaskVersion) {
          return;
        }

        if (prevTaskVersion?.workflow_state !== task.workflow_state && task.parent_id && task.task_type === 'task') {
          const parentTask = TaskUtils.deepFindTaskById(state.tasks, task.parent_id);
          if (parentTask) {
            parentTask.children = parentTask.children?.map((child) => {
              if (child.id === task.id) {
                return task; // it updates the parent.children with the new task data
              }
              return child;
            });

            const notInProgressStates: WorkflowState[] = ['n_a', 'not_started'];
            let parentWorkflowState: WorkflowState;
            if (parentTask.children?.every(({ workflow_state }) => workflow_state === 'n_a')) {
              parentWorkflowState = 'n_a';
            } else if (parentTask.children?.every(({ workflow_state }) => workflow_state === 'complete')) {
              parentWorkflowState = 'complete';
            } else if (
              parentTask.children?.some(({ workflow_state }) => !notInProgressStates.includes(workflow_state))
            ) {
              parentWorkflowState = 'in_progress';
            } else {
              parentWorkflowState = 'not_started';
            }

            parentTask.workflow_state = parentWorkflowState;
            context.dispatch(new TaskActions.UpdateTask(parentTask));
          }
        }

        // check if the dates changes, if yes, dispatch updatecall
        if (
          prevTaskVersion?.start_date !== task.start_date ||
          prevTaskVersion?.due_date !== task.due_date ||
          prevTaskVersion?.completed_at !== task.completed_at ||
          JSON.stringify(prevTaskVersion?.predecessor_task_ids) !== JSON.stringify(task?.predecessor_task_ids)
        ) {
          context.dispatch(new TaskActions.RecalculateTaskListDates(task.task_list_id));
        }
      }),
    );
  }

  @Action(TaskActions.CreateTodo)
  createTodo(context: StateContext<TaskStateModel>) {
    const currentTask = cloneDeep(context.getState().currentTask);

    let rank = 0;
    if (currentTask.todos?.length > 0) {
      rank = currentTask.todos.length - 1;
    }

    let todo = {
      title: '',
      done: false,
      editing: true,
      rank: rank,
    };

    currentTask.todos?.push(todo);

    return this.taskService.updateTaskById(currentTask, null).pipe(
      tap((task) => {
        task.children = TaskUtils.deepSortTasksByRank(task.children);
        const tasks = TaskUtils.deepUpdateTaskById(cloneDeep(context.getState().tasks), task);
        context.patchState({ currentTask: task, tasks });
      }),
    );
  }

  @Action(TaskActions.DeleteTodo)
  deleteTodo(context: StateContext<TaskStateModel>, { task, todo }: { task: Task; todo: Todo }) {
    task.todos = task.todos.map((t) => {
      if (t.id === todo.id) {
        todo['_destroy'] = true;
        return todo;
      }
      return t;
    });

    context.dispatch(new TaskActions.UpdateTask(task));
  }

  @Action(TaskActions.AddApprover)
  addApprover(context: StateContext<TaskStateModel>, { task, approval }: { task: Task; approval: Approval }) {
    const currentTask = cloneDeep(context.getState().currentTask);

    return this.taskService.addApproverToTask(task.task_list_id, task.id, approval).pipe(
      tap((approval) => {
        if (!task.approvals) {
          task.approvals = [];
        }

        task.approvals.push(approval);

        let newState;
        if (task.id === currentTask?.id) {
          newState = { currentTask: task };
        }

        newState = { ...newState, tasks: TaskUtils.deepUpdateTaskById(context.getState().tasks, task) };
        context.patchState(newState);
      }),
    );
  }

  @Action(TaskActions.RemoveApprover)
  removeApprover(context: StateContext<TaskStateModel>, { task, approval }: { task: Task; approval: Approval }) {
    const currentTask = cloneDeep(context.getState().currentTask);
    return this.taskService.removeApproverFromTask(task.task_list_id, task.id, approval).pipe(
      tap(() => {
        task.approvals = task.approvals.filter((a) => a.id !== approval.id);
        let newState;
        if (task.id === currentTask?.id) {
          newState = { currentTask: task };
        }

        newState = { ...newState, tasks: TaskUtils.deepUpdateTaskById(context.getState().tasks, task) };
        context.patchState(newState);
      }),
    );
  }

  @Action(TaskActions.AddFollower)
  addFollower(context: StateContext<TaskStateModel>, { task, user }: { task: Task; user: User }) {
    task.followings.unshift(user);

    const newTask = { ...task, following_user_ids: task.followings.map((user) => user.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.RemoveFollower)
  removeFollower(context: StateContext<TaskStateModel>, { task, user }: { task: Task; user: User }) {
    task.followings.splice(
      task.followings.findIndex((item) => item.id === user.id),
      1,
    );

    const newTask = { ...task, following_user_ids: task.followings.map((user) => user.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.AddContact)
  addContact(context: StateContext<TaskStateModel>, { task, contact }: { task: Task; contact: Contact }) {
    task.contacts.unshift(contact);

    const newTask = { ...task, contact_ids: task.contacts.map((user) => user.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.RemoveContact)
  removeContact(context: StateContext<TaskStateModel>, { task, contact }: { task: Task; contact: Contact }) {
    task.contacts.splice(
      task.contacts.findIndex((item) => item.id === contact.id),
      1,
    );
    const newTask = { ...task, contact_ids: task.contacts.map((contact) => contact.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  // =========================
  // TASK LIST

  @Selector()
  static getTaskList(state: TaskStateModel): TaskList {
    return state?.taskList;
  }

  @Action(TaskActions.GetTaskListById)
  getTaskListById(
    context: StateContext<TaskStateModel>,
    { taskListId, templateId, includeTasks }: { taskListId: number; templateId: number; includeTasks: any },
  ) {
    return this.taskService.getTaskListById(taskListId.toString(), templateId, includeTasks).pipe(
      tap((taskList) => {
        if (includeTasks && taskList.tasks) {
          context.patchState({ tasks: taskList.tasks, taskList, searchedIds: null });
        } else {
          context.dispatch(new TaskActions.GetTasks(taskList.id, {}));
          context.patchState({ taskList, searchedIds: null });
        }
      }),
    );
  }

  @Action(TaskActions.UpdateTaskList)
  updateTaskList(context: StateContext<TaskStateModel>, { taskList }: { taskList: TaskList }) {
    return this.taskService.updateTaskList(taskList).pipe(
      tap((taskList) => {
        context.patchState({ taskList });
      }),
    );
  }

  @Action(TaskActions.RecalculateTaskListDates, { cancelUncompleted: true })
  recalculateTaskListDates(context: StateContext<TaskStateModel>, { taskListId }: { taskListId: number }) {
    return this.taskService
      .recalculateTaskDates({ id: taskListId })
      .pipe(mergeMap(() => context.dispatch(new TaskActions.GetTasks(taskListId, {}))));
  }

  @Action(TaskActions.ClearTaskList)
  clearCurrentTaskList(context: StateContext<TaskStateModel>) {
    context.patchState({ currentTask: null, taskList: null, searchedIds: null, tasks: [] });
  }

  // =========================
  // TASKS
  @Selector()
  static getTasks(state: TaskStateModel): Task[] {
    return state?.tasks;
  }

  @Selector()
  static getSearchedIds(state: TaskStateModel): number[] {
    return state?.searchedIds;
  }

  @Action(TaskActions.GetTasks)
  getTasks(context: StateContext<TaskStateModel>, { taskListId, filters }: { taskListId: number; filters: any }) {
    return this.taskService.getTasks(taskListId, null, {}).pipe(
      tap((tasks) => {
        context.patchState({ tasks: TaskUtils.deepSortTasksByRank(tasks) });
      }),
    );
  }

  @Action(TaskActions.ReorderTasks, { cancelUncompleted: true })
  reorderTasks(
    context: StateContext<TaskStateModel>,
    { taskListId, tasks, tempalteId }: { taskListId: number; tasks: Task[]; tempalteId: number },
  ) {
    return this.taskService
      .updateTasksOrders({ id: taskListId }, tasks, tempalteId)
      .pipe(switchMap(() => context.dispatch(new TaskActions.GetTasks(taskListId, {}))));
  }

  // deletaaction
  @Action(TaskActions.DeleteTask)
  deleteTask(
    context: StateContext<TaskStateModel>,
    { taskListId, taskId, templateId }: { taskListId: number; taskId: number; templateId: number },
  ) {
    return this.taskService.deleteTask(taskListId, templateId, taskId).pipe(
      map(() => {
        const currentTask = cloneDeep(context.getState().currentTask);
        let deletingCurrentTaskChild = false;

        const filterTask = (arr, id) => {
          return arr.filter((task, index) => {
            if (task.id === id) {
              deletingCurrentTaskChild = task.parent_id === currentTask.id;
              return false;
            }
            if (task.children) {
              task.children = filterTask(task.children, id);
            }
            return true;
          });
        };

        const state = context.getState();
        const newTasksArray = filterTask(state.tasks, taskId);
        let newState = { tasks: newTasksArray };

        if (deletingCurrentTaskChild) {
          currentTask.children = filterTask(currentTask?.children, taskId);
          newState['currentTask'] = currentTask;
        }

        context.patchState(newState);
      }),
    );
  }

  @Action(TaskActions.FilterTasks)
  filterTasks(context: StateContext<TaskStateModel>, { queryFilters }) {
    const taskList = cloneDeep(context.getState().taskList);
    const tempFilter = queryFilters.fjson ? JSON.parse(queryFilters.fjson) : [];
    tempFilter.push(['task_list_id', 'eq', taskList?.id]);

    const filter = {
      ...queryFilters,
      fjson: JSON.stringify(tempFilter),
    };

    return this.taskService.search(filter).pipe(
      tap((tasks) => {
        const searchedIds = tasks.map((task) => task.id);
        taskList.definition.filters = JSON.stringify(queryFilters);
        context.patchState({ searchedIds });
      }),
    );
  }

  @Action(TaskActions.ClearFilter)
  clearFilter(context: StateContext<TaskStateModel>, { fjson, sort, q, include_keydates, select }) {
    context.patchState({ searchedIds: null });
  }
}
