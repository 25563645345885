<section class="ts-page w-full h-full flex flex-row flex-nowrap">
  @if (displaySidenav) {
    <aside
      class="ts-page__sidenav bg-white w-full overflow-y-auto h-full p-2 transition-max-width-and-opacity duration-300 ease-in-out overflow-hidden"
      [ngClass]="(sidebarMode | async) === 'expanded' ? 'max-w-72' : 'max-w-12'"
    >
      <div>
        <ng-container *ngIf="(sidebarMode | async) === 'expanded'; else sidebarCollapsed">
          <div *ngIf="sidenavConfig" class="ts-page__sidenav__header">
            <span class="truncate">{{ sidenavConfig.title }}</span>
            <div class="flex flex-row flex-nowrap gap-1 place-content-center">
              <ng-container *ngFor="let action of sidenavConfig?.actions">
                <ng-container
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{ $implicit: action }"
                ></ng-container>
              </ng-container>
              <ng-container [ngTemplateOutlet]="toggleButton"></ng-container>
            </div>
          </div>
          <ng-content select="[sidenav]"></ng-content>
        </ng-container>
        <ng-template #sidebarCollapsed>
          <div class="ts-page__sidenav__header">
            <ng-container [ngTemplateOutlet]="toggleButton"></ng-container>
          </div>
        </ng-template>
      </div>
    </aside>
    <div class="ts-page__divider"></div>
  }

  <div class="ts-page__main-content w-full h-full relative overflow-y-auto bg-white">
    <ng-content select="[main]"></ng-content>
  </div>
</section>

<ng-template #toggleButton>
  <div class="flex flex-row place-content-center">
    <button
      class="flex flex-row place-content-center"
      type="button"
      (click)="toggleSidebarMode()"
      [matTooltip]="(sidebarMode | async) === 'expanded' ? 'Collapse' : 'Expand'"
    >
      <ts-icon icon="ts_side-menu"></ts-icon>
    </button>
  </div>
</ng-template>

<ng-template #actionButton let-action>
  <div class="ts-page__sidenav__action flex flex-row place-content-center">
    <button
      class="flex flex-row place-content-center"
      type="button"
      (click)="action.actionFn()"
      [matTooltip]="action?.description"
    >
      <ts-icon [icon]="action.icon"></ts-icon>
    </button>
  </div>
</ng-template>
