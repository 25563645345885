import { User } from 'app/core/models/user.types';
import { Task } from '../legacy-tasks/tasks.types';
import { Project } from 'app/modules/projects/projects.types';
import { RelatedToType } from 'app/shared/enums';

export enum ApprovalStatus {
  NONE = 'none',
  NOT_SENT = 'not_sent',
  NOT_YET = 'not_yet',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export interface Approval {
  id: number;
  active: boolean;
  status: ApprovalStatus;
  assigned_user: Partial<User>;
  comments: string;
  related_to_type?: RelatedToType;
  related_to?: Task | Project;
}
